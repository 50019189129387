import {atom} from 'recoil';

export interface PatientConnectionType {
  id?: string | number;
  consumerUuid?: string;
  clientId?: string | number;
  nameDisplay?: string;
  clientFirstName?: string;
  clientLastName?: string;
  relyingPartyId?: string | number;
  issuerId?: string | number;
  relyingPartyName?: string;
  providerDirectoryLocationId?: string | number;
  sharePatientToProvider?: boolean;
  shareProviderToPatient?: boolean;
  enableAssistedMode?: boolean;
  initiatingType?: string;
  connectionType?: string;
  status?: string;
  relyingPartyProviderType?: string;
  relyingPartyType?: string;
  medicalRecordsRequestedAt?: string;
  providerDirectoryLocation?: string;
  expiresAt?: string;
  connectedBy?: string;
  is_sponsor: boolean;
}

const defaultSelectedConnection: PatientConnectionType = {
  id: '',
  consumerUuid: '',
  clientId: '',
  clientFirstName: '',
  clientLastName: '',
  relyingPartyId: '',
  issuerId: '',
  relyingPartyName: '',
  providerDirectoryLocationId: '',
  sharePatientToProvider: false,
  shareProviderToPatient: false,
  enableAssistedMode: false,
  initiatingType: '',
  connectionType: '',
  status: '',
  relyingPartyProviderType: '',
  relyingPartyType: '',
  medicalRecordsRequestedAt: '',
  providerDirectoryLocation: '',
  expiresAt: '',
  connectedBy: '',
  is_sponsor: false,
};

export const selectedConnectionState = atom<PatientConnectionType>({
  key: 'selectedConnectionState',
  default: defaultSelectedConnection,
});
