import React, {FC, useEffect, useMemo, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import styled from 'styled-components';

import {Button} from '../../../../shared/components/button';
import {colors} from '../../../../shared/styles/theme';
import {chooseProviderDisplayNames} from '../../utils/chooseProviderName';
import {fetchDeliveryTime} from '../../utils/fetchers';
import {
  getOrderRecordsAddress,
  getOrderRecordsAddressConnection,
} from '../../utils/getProviderDirectoryAddress';

type Props = {
  selectedRequestType: string[] | null;
  selectedDeliveryMethod: string | null;
  onNext: () => void;
};

enum ProviderTypes {
  ORGANIZATION = 'ORGANIZATION',
  EXTERNAL_ORGANIZATION = 'EXTERNAL_ORGANIZATION',
}

enum DeliveryMethod {
  standard = 'Standard Delivery',
}

export const OrderRequestSummary: FC<Props> = ({
  selectedRequestType,
  selectedDeliveryMethod,
  onNext,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [deliveryTime, setDeliveryTime] = useState<any>(null);
  const {rowData} = location.state as {rowData: any};
  const isIndividual = useMemo(
    () =>
      rowData?.relying_party_provider_type !==
        ProviderTypes.EXTERNAL_ORGANIZATION &&
      rowData?.relying_party_provider_type !== ProviderTypes.ORGANIZATION &&
      rowData?.client_last_name &&
      rowData?.client_last_name?.length > 0,
    [rowData?.client_last_name, rowData?.relying_party_provider_type]
  );
  const {nameDisplay, organizationDisplay} = chooseProviderDisplayNames(
    rowData.client_first_name ? rowData : rowData.provider_directory_location
  );
  let address = '';
  if (rowData.provider_directory_location_string) {
    address = rowData.provider_directory_location_string;
  } else if (rowData.provider_directory_location?.address_1) {
    address = getOrderRecordsAddress(rowData.provider_directory_location);
  } else if (rowData.provider_directory_location) {
    address = getOrderRecordsAddressConnection(
      rowData.provider_directory_location
    );
  }

  const handleRedirect = async () => {
    history.replace(
      `/portal-patients/connections/${rowData.consumer_uuid}/view-orders`
    );
    onNext();
  };

  useEffect(() => {
    const getDeliveryTime = async () => {
      try {
        const arrayTime = await fetchDeliveryTime(rowData?.relying_party_id);

        let relevantInfo = {
          delivery_days: {},
        };

        const deliveryDays = (type: string) =>
          arrayTime.find(
            (i: {document_type: string}) => i.document_type === type
          )?.delivery_days;

        selectedRequestType?.forEach(type => {
          relevantInfo = {
            ...relevantInfo,
            delivery_days: {
              ...relevantInfo.delivery_days,
              [type]: deliveryDays(type),
            },
          };
        });
        setDeliveryTime(relevantInfo);
      } catch (error) {
        console.log('error', error);
      }
    };
    getDeliveryTime();
  }, [rowData?.relying_party_id, selectedRequestType]);

  const getRequestedTypeSelect = (selectedType: string) => {
    if (selectedType === 'TEXT') {
      return 'Medical Records';
    }
    if (selectedType === 'IMAGE') {
      return 'Images';
    }
    return 'Billing Records';
  };

  const getDeliverySpeed = (
    selectedRequestType: string | null,
    deliveryTime: any
  ) => {
    if (
      !selectedRequestType ||
      !deliveryTime?.delivery_days[selectedRequestType]
    ) {
      return 'Provider Averages 16 Days';
    }
    return `Provider Averages ${Math.floor(deliveryTime.delivery_days[selectedRequestType])} Days`;
  };

  return (
    <Container>
      <Title>Order Complete</Title>
      <Description>
        You have successfully completed your record order.
      </Description>
      <TextGroup>
        {nameDisplay && (
          <TitleText>
            {nameDisplay}
            {isIndividual && ' & Associates'}
          </TitleText>
        )}
        {organizationDisplay && (
          <DescriptionText>{organizationDisplay}</DescriptionText>
        )}
        {address && <DescriptionText>{address}</DescriptionText>}
      </TextGroup>
      <SubHeader>Order Summary</SubHeader>
      <SummaryContainer>
        <Content>
          <ContentSummary>
            {selectedRequestType?.map((type, index) => (
              <>
                <DeliveryDetails>
                  <DeliveryDetailsKey>Record Type</DeliveryDetailsKey>
                  <DeliveryDetailsValue>
                    {getRequestedTypeSelect(type || '')}
                  </DeliveryDetailsValue>
                </DeliveryDetails>
                <DeliveryDetails>
                  <DeliveryDetailsKey>Delivery Method</DeliveryDetailsKey>
                  <DeliveryDetailsValue>
                    {selectedDeliveryMethod &&
                      DeliveryMethod[
                        selectedDeliveryMethod as keyof typeof DeliveryMethod
                      ]}
                  </DeliveryDetailsValue>
                </DeliveryDetails>
                <DeliveryDetails>
                  <DeliveryDetailsKey>Delivery Speed</DeliveryDetailsKey>
                  <DeliveryDetailsValue>
                    {getDeliverySpeed(type, deliveryTime)}
                  </DeliveryDetailsValue>
                </DeliveryDetails>
                <DeliveryDetailsBorder />
              </>
            ))}
          </ContentSummary>
          <DeliveryDetailsInstructions>
            We will notify you when the provider delivers the patient’s records.
            Digital Downloads are delivered within minutes.
            <br />
            Standard Deliveries typically take one to two weeks.
            <br />
            <br />
            The patient is legally entitled to their records without charge.
            Please report any provider who blocks delivery or requests payment
            to:{' '}
            <DeliveryDetailsEmail
              onClick={() => {
                window.location.href = 'mailto:support@healthbankone.com';
              }}
            >
              support@healthbankone.com
            </DeliveryDetailsEmail>
          </DeliveryDetailsInstructions>
        </Content>
      </SummaryContainer>
      <ButtonContainer>
        <Button onClick={handleRedirect}>Finish</Button>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;
`;
const Title = styled.text`
  font-size: 18px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  line-height: 24px;
  color: ${colors.black};
  margin-bottom: -4px;
`;

const Description = styled.text`
  font-size: 14px;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  line-height: 16px;
  color: ${colors.grey801};
  font-style: italic;
  margin-bottom: 8px;
`;
const ButtonContainer = styled.div`
  margin-top: 24px;
`;
const SummaryContainer = styled.div`
  width: 464px;
  height: auto;
  padding: 16px 16px 16px 16px;
  gap: 16px;
  border-radius: 4px 4px 4px 4px;
  border: 1px 1px 1px 1px;
  border: 1px solid ${colors.grey1100};
`;

const Content = styled.div`
  width: 432px;
  height: auto;
  gap: 8px;
  opacity: 0px;
`;

const ContentSummary = styled.div`
  height: auto;
  gap: 8px;
  display: flex;
  flex-direction: column;
  width: 464px;
`;

const DeliveryDetails = styled.div`
  width: 432px;
  height: 24px;
  opacity: 0px;
  display: flex;
  flex-direction: row;
`;
const DeliveryDetailsBorder = styled.div`
  margin: 4px 0px;
  border-top: 1px solid ${colors.grey1100};
`;
const DeliveryDetailsInstructions = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  padding-top: 12px;
`;

const DeliveryDetailsEmail = styled.text`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  display: inline;
  color: ${colors.blue};
  cursor: pointer;
`;

const DeliveryDetailsKey = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  width: 108px;
  height: 24px;
  opacity: 80%;
  margin-right: 16px;
`;

const DeliveryDetailsValue = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const TextGroup = styled.div`
  height: auto;
  width: 464px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 16px 16px 16px;
  border: 1px solid ${colors.grey1100};
  border-radius: 4px;
`;

const TitleText = styled.text`
  font-size: 16px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  line-height: 24px;
  color: ${colors.black};
`;

const DescriptionText = styled.text`
  font-size: 14px;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  line-height: 21px;
  color: ${colors.black};
`;

const SubHeader = styled.text`
  font-size: 14px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  line-height: 24px;
  color: ${colors.black};
  margin-top: 4px;
  margin-bottom: 4px;
`;
