import React, {FC, Fragment, useCallback, useEffect, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {CircularProgress, Divider, Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import styled from 'styled-components';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {CloseButton} from '../../../shared/components/closeButton';
import {closeDrawer} from '../../../shared/components/drawer';
import {
  List,
  ListItemKey,
  ListItemValue,
} from '../../../shared/components/list';
import {UploadRecordsIcon} from '../../../shared/icons/uploadRecordsIcon';
import {colors} from '../../../shared/styles/theme';
import {getFullName} from '../../../shared/utils/getFullName';
import {DRAWER_DEFAULT_STATE, drawerState} from '../atoms/drawerState';
import {selectedPatientState} from '../atoms/selectedPatientState';
import {CONNECTION_STATUSES} from '../constants/patientStatuses';
import {PORTAL_PATIENTS_ROUTES} from '../constants/routes';
import {AvatarSize} from '../constants/types';
import {usePatientDetails} from '../hooks/usePatientDetails';

import {ConnectedPatientStatusLabel} from './connectedPatientStatusLabel';
import {DrawerHeaderTitle} from './drawerHeaderTitle';
import {PatientDocuments} from './patientDocuments';
import {PatientEhrsList} from './patientEhrList';
import {ProfileAvatar} from './profileAvatar';
import {ViewMedicalRecordsBtn} from './viewMedicalRecordsBtn';

const CIRCULAR_PROGRESS_PROPS = {
  size: 120,
  thickness: 2,
};

export const PatientDetailsDrawerContent: FC<{contentWidth: number}> = ({
  contentWidth,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const setDrawerState = useSetRecoilState(drawerState);
  const selectedPatient = useRecoilValue(selectedPatientState);
  const currentUser = useRecoilValue(currentUserState);

  const {
    data: patientDetails,
    isLoading: isPatientDetailsLoading,
    isError: errorFetchPatientsDetails,
  } = usePatientDetails(
    selectedPatient.patientUuid ?? null,
    currentUser?.relyingParty.id ?? null
  );

  const isShowDrawerContent =
    patientDetails && !isPatientDetailsLoading && !errorFetchPatientsDetails;

  const handleClose = useCallback((): void => {
    setDrawerState(DRAWER_DEFAULT_STATE);
  }, [setDrawerState]);

  const handleClick = useCallback(() => {
    handleClose();
    history.push(
      PORTAL_PATIENTS_ROUTES.UPLOAD_MEDICAL_RECORDS_DATA.replace(
        ':patientUuid',
        patientDetails?.publicDetails.consumerUuid ?? ''
      )
    );
  }, [handleClose, history, patientDetails?.publicDetails.consumerUuid]);

  useEffect(() => {
    closeDrawer(handleClose, ref);
  }, [handleClose]);

  return (
    <ComponentWrapper
      ref={ref}
      width={contentWidth}
      data-testid="patient-details-drawer"
    >
      <Header>
        <TitleWrapper>
          {!isPatientDetailsLoading && !errorFetchPatientsDetails
            ? 'Patient Details'
            : ''}
        </TitleWrapper>
        <CloseButton onClick={handleClose} data-testid="close-drawer-btn" />
      </Header>
      {isPatientDetailsLoading && !errorFetchPatientsDetails && (
        <CenteringWrapper>
          <CircularProgress {...CIRCULAR_PROGRESS_PROPS} />
        </CenteringWrapper>
      )}
      {isShowDrawerContent ? (
        <>
          <SubHeader>
            <ProfileAvatar
              firstName={patientDetails.publicDetails.firstName}
              lastName={patientDetails.publicDetails.lastName}
              src={patientDetails.publicDetails.src}
              size={AvatarSize.small}
            />
            <div>
              <DrawerHeaderTitle
                title={getFullName(
                  patientDetails.publicDetails.firstName,
                  patientDetails.publicDetails.lastName,
                  patientDetails.publicDetails.middleName
                )}
              />
              <ConnectedPatientStatusLabel
                status={patientDetails.connection.status}
              />
            </div>
          </SubHeader>
          <ButtonsWrapper>
            <ViewMedicalRecordsBtn
              patientUuid={patientDetails.publicDetails.consumerUuid}
              patientName={getFullName(
                patientDetails.publicDetails.firstName,
                patientDetails.publicDetails.lastName
              )}
              isPatientConnected={
                patientDetails.connection.status ===
                CONNECTION_STATUSES.CONNECTED
              }
              onClick={handleClose}
              isSharingMedicalRecords={
                patientDetails.connection.share_patient_to_provider
              }
            />
            <Button
              color="primary"
              size="small"
              variant="outlined"
              startIcon={<UploadRecordsIcon />}
              onClick={handleClick}
            >
              Upload Records
            </Button>
          </ButtonsWrapper>
          <List>
            {[
              ...patientDetails.personalDetails,
              ...patientDetails.addressDetails,
            ].map(({title, value}) => (
              <Fragment key={title}>
                <ListItemKey key={title}>{title}</ListItemKey>
                <ListItemValue key={value}>{value}</ListItemValue>
              </Fragment>
            ))}
          </List>
          <Divider />
          <PatientEhrsList
            consumerUuid={patientDetails?.publicDetails.consumerUuid}
            items={patientDetails.mrnDetails}
            patientName={getFullName(
              patientDetails.publicDetails.firstName,
              patientDetails.publicDetails.lastName,
              patientDetails.publicDetails.middleName
            )}
          />
          <Divider />
          <PatientDocuments
            primaryInsuranceId={patientDetails.publicDetails.primaryInsuranceId}
            secondaryInsuranceId={
              patientDetails.publicDetails.secondaryInsuranceId
            }
            consumerUuid={patientDetails.publicDetails.consumerUuid}
            patientFirstName={patientDetails.publicDetails.firstName}
            patientLastName={patientDetails.publicDetails.lastName}
          />
        </>
      ) : null}
    </ComponentWrapper>
  );
};

const ComponentWrapper = styled.div<{width: number}>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 100%;
  padding: 24px 40px;
  width: ${props => `${props.width}px`};
  border-top: 1px solid ${colors.grey800};
  box-sizing: border-box;
`;

const SubHeader = styled.div`
  display: inline-grid;
  grid-template-columns: max-content auto;
  grid-gap: 24px;
  margin: 0;
`;

const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 8px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
`;

const TitleWrapper = styled(Typography)`
  font-weight: 600;
  color: ${colors.black};
  font-size: 16px;
`;

const CenteringWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
`;
