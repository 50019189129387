import React, {FC, useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Typography} from '@material-ui/core';
import {getCoreRowModel, useReactTable} from '@tanstack/react-table';
import {useRecoilState} from 'recoil';
import styled from 'styled-components';

import {AddButton} from '../../../../shared/components/button';
import {ConfirmModal} from '../../../../shared/components/confirmModal';
import {Drawer} from '../../../../shared/components/drawer';
import {LoadingBar} from '../../../../shared/components/loadingBar';
import {NoDataPlaceholder} from '../../../../shared/components/noDataPlaceholder';
import {SmthWentWrongPlaceholder} from '../../../../shared/components/smthWentWrongPlaceholder';
import {StyledTable} from '../../../../shared/components/table/styledTable';
import {patientConnectionType} from '../../../../shared/constants/patientConnectionType';
import {useCustomizedSnackbar} from '../../../../shared/hooks/useCustomizedSnackbar';
import {useErrorHandling} from '../../../../shared/hooks/useErrorHandling';
import {capitalizeParser} from '../../../../shared/utils/captalizeParser';
import {drawerState} from '../../atoms/drawerState';
import {selectedConnectionState} from '../../atoms/selectedConnection';
import {getOrderRecordsTableCells} from '../../constants/orderRecordsTableConfig';
import {CONNECTION_STATUSES} from '../../constants/patientStatuses';
import {PORTAL_PATIENTS_ROUTES} from '../../constants/routes';
import {
  DrawerTypes,
  PatientConnections,
  PatientConnectionsResponse,
} from '../../constants/types';
import {editConnectionPreferences} from '../../utils/fetchers';
import {getPatientConnections} from '../../utils/getPatientConnections';
import {ProviderDetailsDrawer} from '../addProvider/providerDetailsDrawer';
import {PermissionDenied} from '../permissionDenied';

type Props = {
  testId: string;
  consumerUuid: string;
};
const DRAWER_WIDTH = 460;

export const OrderRecordsTab: FC<Props> = ({testId, consumerUuid}) => {
  const [data, setData] = useState<PatientConnectionsResponse[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(0);
  const successMessage = useCustomizedSnackbar();
  const handleError = useErrorHandling();
  const [isShowReceivingDisabledModal, setIsShowReceivingDisabledModal] =
    useState(false);
  const [isShowSharingRequiredModal, setIsShowSharingRequiredModal] =
    useState(false);
  const [isShowDisconnectModal, setIsShowDisconnectModal] = useState(false);

  const history = useHistory();

  const onClickOrderRecords = useCallback(
    (rowData: PatientConnections) => {
      if ((rowData as patientConnectionType).share_provider_to_patient) {
        history.push({
          pathname: PORTAL_PATIENTS_ROUTES.ORDER_RECORDS,
          state: {rowData, consumerUuid},
        });
      } else {
        setIsShowReceivingDisabledModal(true);
      }
    },
    [consumerUuid, history]
  );

  const [drawer, setDrawerState] = useRecoilState(drawerState);
  const [selectedConnection, setSelectedConnection] = useRecoilState(
    selectedConnectionState
  );

  const columns = getOrderRecordsTableCells(
    onClickOrderRecords,
    setDrawerState,
    setIsShowDisconnectModal,
    setSelectedConnection
  );

  const onConfirmSharingModal = useCallback(() => {
    setIsShowReceivingDisabledModal(false);
    setDrawerState({
      isOpen: true,
      patientUuid: null,
      currentDrawer: DrawerTypes.PROVIDER_DETAILS,
    });
  }, [setDrawerState]);

  const fetchDataNoLoading = useCallback(async () => {
    setIsError(false);
    try {
      const result = await getPatientConnections(consumerUuid);
      setData(result || []);
    } catch (error) {
      setIsError(true);
    }
  }, [consumerUuid]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await getPatientConnections(consumerUuid);
      setIsLoading(false);
      setData(result || []);
    } catch (error) {
      setError(JSON.parse((error as Error).message).status);
      setIsError(true);
      setIsLoading(false);
    }
  }, [consumerUuid]);

  const onConfirmDisconnect = useCallback(async () => {
    try {
      await editConnectionPreferences(
        selectedConnection.id,
        selectedConnection.consumerUuid ?? '',
        selectedConnection.shareProviderToPatient,
        selectedConnection.sharePatientToProvider,
        CONNECTION_STATUSES.DISCONNECTED
      );
      setIsShowDisconnectModal(false);
      fetchData();
      successMessage(
        `You have successfully disconnected from ${capitalizeParser(selectedConnection.nameDisplay)}.`,
        'success'
      );
    } catch (e) {
      await handleError(e);
    }
  }, [
    fetchData,
    handleError,
    selectedConnection.consumerUuid,
    selectedConnection.id,
    selectedConnection.nameDisplay,
    selectedConnection.sharePatientToProvider,
    selectedConnection.shareProviderToPatient,
    successMessage,
  ]);

  useEffect(() => {
    fetchData();
  }, [consumerUuid, fetchData]);

  const table = useReactTable<PatientConnectionsResponse>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const renderErrorPlaceholder = () => {
    if (!isError) return null;

    return error === 403 ? (
      <PermissionDenied errorMessageFrom="order records" />
    ) : (
      <SmthWentWrongPlaceholder
        labelText="Something went wrong during request processing. Please retry."
        onClick={() => window.location.reload()}
      />
    );
  };

  return (
    <TabWrapper data-testid={testId}>
      <LoadingBar loading={isLoading} />
      {isError && (
        <CenteringErrorPlaceholder>
          {renderErrorPlaceholder()}
        </CenteringErrorPlaceholder>
      )}
      {!isLoading && !isError && (
        <>
          {data.length === 0 ? (
            <NoDataPlaceholderWrapper>
              <NoDataPlaceholder noDataText="There are no order records available." />
            </NoDataPlaceholderWrapper>
          ) : (
            <TableWrapper>
              <HeaderWrapper>
                <ButtonBox>
                  <TextAddButton>
                    Need records from another provider?
                  </TextAddButton>
                  <AddButton
                    text="Add Provider"
                    onClick={() =>
                      history.push(
                        PORTAL_PATIENTS_ROUTES.CONNECTIONS_ADD_PROVIDER.replace(
                          ':patientUuid',
                          consumerUuid
                        )
                      )
                    }
                    data-testid="addProviderButton"
                  />
                </ButtonBox>
              </HeaderWrapper>
              <Container>
                <StyledTable
                  table={table}
                  testId="order-records-table"
                  placeholderComponent={null}
                />
                <DrawerContainer id="provider-details" />
              </Container>{' '}
            </TableWrapper>
          )}
        </>
      )}
      <Drawer
        drawerWidth={DRAWER_WIDTH}
        data-testid="provider-details-drawer"
        elementId="provider-details"
      >
        {drawer.currentDrawer === DrawerTypes.PROVIDER_DETAILS && (
          <ProviderDetailsDrawer
            contentWidth={DRAWER_WIDTH}
            fetchData={fetchDataNoLoading}
            setIsShowSharingRequiredModal={setIsShowSharingRequiredModal}
          />
        )}
      </Drawer>{' '}
      <ConfirmModal
        maxWidth={560}
        minWidth={560}
        confirmBtnText="Turn on Receiving"
        isOpen={isShowReceivingDisabledModal}
        onClose={() => setIsShowReceivingDisabledModal(false)}
        variant="h6"
        title="Receive Records is not turned on"
        onConfirm={onConfirmSharingModal}
      >
        <ModalDescription>
          To order medical records from this provider, record receiving must be
          turned on. Please go to Patient Preferences to turn on Receive
          Records.
        </ModalDescription>
      </ConfirmModal>
      <ConfirmModal
        maxWidth={590}
        minWidth={590}
        confirmBtnText="Close"
        isOpen={isShowSharingRequiredModal}
        onClose={() => setIsShowSharingRequiredModal(false)}
        variant="h6"
        isShowCancelBtn={false}
        title="Sharing is required to maintain sponsored membership"
        onConfirm={() => setIsShowSharingRequiredModal(false)}
      >
        <ModalDescription>
          You cannot turn off sharing. This organization is sponsoring the
          patient. Sharing is required for the patient to maintain their free
          sponsored membership.
        </ModalDescription>
      </ConfirmModal>
      <ConfirmModal
        maxWidth={480}
        minWidth={480}
        confirmBtnText="Disconnect"
        isOpen={isShowDisconnectModal}
        onClose={() => setIsShowDisconnectModal(false)}
        variant="h6"
        confirmBtnVariant="dangerous"
        title="Disconnect Provider?"
        disabled={selectedConnection.is_sponsor}
        onConfirm={onConfirmDisconnect}
      >
        {selectedConnection.is_sponsor ? (
          <ModalDescription>
            You cannot disconnect this organization. This organization is
            sponsoring the patient. This connection is required for the patient
            to maintain their free membership.
          </ModalDescription>
        ) : (
          <>
            <ModalDescription>
              If you disconnect, you will not be able to share, receive or order
              records from <b> {selectedConnection.nameDisplay}</b>
            </ModalDescription>
            <ModalDescription>
              Should you want to reconnect with this provider in the future, you
              can add the provider by clicking the add provider button.
            </ModalDescription>
          </>
        )}
      </ConfirmModal>
    </TabWrapper>
  );
};
const DrawerContainer = styled.div``;

const Container = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
`;

const TabWrapper = styled.div`
  position: relative;
`;

const TableWrapper = styled.div`
  white-space: break-spaces;
  margin: 20px 0 24px 0;
  .MuiIconButton-sizeSmall {
    padding: 1px;
  }
  .MuiTableCell-root.MuiTableCell-body.stickyRight {
    padding-left: 26px;
  }
  .MuiTableRow-root.MuiTableRow-hover {
    height: 80px;
  }
  .MuiTableRow-root.MuiTableRow-head {
    height: 56px;
  }

  .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
    padding: 6px;
  }
`;

const NoDataPlaceholderWrapper = styled.div`
  margin-bottom: 80px;
`;

const CenteringErrorPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 100px 0;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;
`;

const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const TextAddButton = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  text-align: right;
`;

const ModalDescription = styled(Typography)`
  font-size: 14px;
  margin: 17px 0 30px;
`;
