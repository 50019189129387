import {useMemo} from 'react';

import {ROLES} from '../../../shared/constants/roles';
import {USER_TYPES} from '../../../shared/constants/userTypes';
import {User} from '../../../shared/interfaces/user';
import {isRoleMatched} from '../../../shared/utils/user';
import {CONNECTION_STATUSES} from '../constants/patientStatuses';

interface UsePatientPermissionsProps {
  patientDetails?: any;
  currentUser?: User | null;
  isPatientDetailsLoading: boolean;
}

interface UsePatientPermissionsReturn {
  isPatientConnected: boolean;
  isSharingMedicalRecords: boolean;
  isPhysicianHasRightRolesForViewRecords: boolean;
  isNonPhysicianHasRightRolesForViewRecords: boolean;
  isShowDetails: boolean;
}

export const usePatientPermissions = ({
  patientDetails,
  currentUser,
  isPatientDetailsLoading,
}: UsePatientPermissionsProps): UsePatientPermissionsReturn => {
  const isPatientConnected = useMemo(
    () => patientDetails?.connection?.status === CONNECTION_STATUSES.CONNECTED,
    [patientDetails]
  );

  const isSharingMedicalRecords = useMemo(
    () => !!patientDetails?.connection?.share_patient_to_provider,
    [patientDetails]
  );

  const isPhysicianHasRightRolesForViewRecords = useMemo(
    () =>
      isRoleMatched(currentUser?.roles, [
        ROLES.RELYING_PARTY_OWNER,
        ROLES.RELYING_PARTY_ADMIN,
        ROLES.RELYING_PARTY_RECORDS_VIEWER,
        ROLES.RELYING_PARTY_PATIENT_ASSISTANT,
      ]) &&
      currentUser?.type === USER_TYPES.PHYSICIAN &&
      isPatientConnected &&
      isSharingMedicalRecords,
    [currentUser, isPatientConnected, isSharingMedicalRecords]
  );

  const isNonPhysicianHasRightRolesForViewRecords = useMemo(
    () =>
      isRoleMatched(currentUser?.roles, [
        ROLES.RELYING_PARTY_RECORDS_VIEWER,
        ROLES.RELYING_PARTY_PATIENT_ASSISTANT,
      ]) &&
      currentUser?.type === USER_TYPES.NON_PHYSICIAN &&
      isPatientConnected &&
      isSharingMedicalRecords,
    [currentUser, isPatientConnected, isSharingMedicalRecords]
  );

  const isShowDetails = useMemo(
    () =>
      !isPatientDetailsLoading &&
      patientDetails &&
      (isPhysicianHasRightRolesForViewRecords ||
        isNonPhysicianHasRightRolesForViewRecords),
    [
      isPatientDetailsLoading,
      patientDetails,
      isPhysicianHasRightRolesForViewRecords,
      isNonPhysicianHasRightRolesForViewRecords,
    ]
  );

  return {
    isPatientConnected,
    isSharingMedicalRecords,
    isPhysicianHasRightRolesForViewRecords,
    isNonPhysicianHasRightRolesForViewRecords,
    isShowDetails,
  };
};
