import React, {FC} from 'react';
import styled from 'styled-components';

import {colors} from '../../../shared/styles/theme';

type Props = {
  title: string;
  description: string | JSX.Element;
};

export const MainHeader: FC<Props> = ({title, description}) => (
  <div>
    <Title>{title}</Title>
    <Description data-testid="main-header-description">
      {description}
    </Description>
  </div>
);

const Title = styled.p`
  font-size: 18px;
  margin: 0;
  font-weight: bold;
`;

const Description = styled.p`
  font-size: 14px;
  margin: 12px 0 8px;
  font-style: italic;
  color: ${colors.grey801};
`;
