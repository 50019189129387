import {PatientConnectionType} from '../atoms/selectedConnection';
import {PatientConnectionsResponse} from '../constants/types';
import {getOrderRecordsAddress} from '../utils/getProviderDirectoryAddress';

export const fromResponse = (
  response: PatientConnectionsResponse
): PatientConnectionType => ({
  id: response.id,
  consumerUuid: response.consumer_uuid,
  clientId: response.client_id,
  nameDisplay: `${response?.client_first_name} ${response?.client_last_name}`,
  clientFirstName: response.client_first_name,
  clientLastName: response.client_last_name,
  relyingPartyId: response.relying_party_id,
  issuerId: response.issuer_id,
  relyingPartyName: response.relying_party_name,
  providerDirectoryLocationId: response.provider_directory_location_id,
  sharePatientToProvider: response.share_patient_to_provider,
  shareProviderToPatient: response.share_provider_to_patient,
  enableAssistedMode: response.enable_assisted_mode,
  initiatingType: response.initiating_type,
  connectionType: response.connection_type,
  status: response.status,
  relyingPartyProviderType: response.relying_party_provider_type,
  relyingPartyType: response.relying_party_type,
  medicalRecordsRequestedAt: response.medical_records_requested_at,
  providerDirectoryLocation: response.provider_directory_location
    ? getOrderRecordsAddress(response.provider_directory_location)
    : '',
  expiresAt: response.expires_at,
  connectedBy: response.connected_by,
  is_sponsor: response.is_sponsor ?? false,
});

export const toResponse = (
  connection: PatientConnectionType
): PatientConnectionsResponse => ({
  id: connection.id,
  consumer_uuid: connection.consumerUuid,
  client_id: connection.clientId,
  client_first_name: connection.clientFirstName,
  client_last_name: connection.clientLastName,
  relying_party_id: connection.relyingPartyId,
  issuer_id: connection.issuerId,
  relying_party_name: connection.relyingPartyName,
  provider_directory_location_id: connection.providerDirectoryLocationId,
  share_patient_to_provider: connection.sharePatientToProvider,
  share_provider_to_patient: connection.shareProviderToPatient,
  enable_assisted_mode: connection.enableAssistedMode,
  initiating_type: connection.initiatingType,
  connection_type: connection.connectionType,
  status: connection.status,
  relying_party_provider_type: connection.relyingPartyProviderType,
  relying_party_type: connection.relyingPartyType,
  medical_records_requested_at: connection.medicalRecordsRequestedAt,
  provider_directory_location_string: connection.providerDirectoryLocation,
  expires_at: connection.expiresAt,
  connected_by: connection.connectedBy,
  is_sponsor: connection.is_sponsor,
});
