import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {queryClient} from '../../../../queryClient';
import {currentUserState} from '../../../shared/atoms/authAtom';
import {isOpenAccessDeniedModalState} from '../../../shared/atoms/isOpenAccessDeniedModalState';
import {Button} from '../../../shared/components/button';
import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {PORTAL_PATIENTS_ROUTES} from '../../portalPatients/constants/routes';
import {usePatientDetails} from '../../portalPatients/hooks/usePatientDetails';
import {usePatientPermissions} from '../../portalPatients/hooks/usePatientPermissions';
import {
  EVENTS_CATEGORY_DROPDOWN,
  ResolutionEnum,
  TABLE_EVENT_ENUM,
} from '../constants/types';
import {useCustomizedButtonStyles} from '../hooks/useCustomizedButtonStyles';
import {fetchInboxProviderResolutionEvent} from '../utils/fetchers';

type Props = {
  consumerId: string;
  eventId: string;
  eventEntityType: TABLE_EVENT_ENUM;
  patientName: string;
};

export const ViewMedicalRecordsButton: React.FC<Props> = ({
  consumerId,
  eventId,
  eventEntityType,
  patientName,
}) => {
  const openAccessDeniedModal = useSetRecoilState(isOpenAccessDeniedModalState);
  const classes = useCustomizedButtonStyles();
  const snackbar = useCustomizedSnackbar();

  const history = useHistory();

  const currentUser = useRecoilValue(currentUserState);

  const {data: patientDetails, isLoading: isPatientDetailsLoading} =
    usePatientDetails(consumerId, currentUser?.relyingParty.id ?? null);

  const {
    isPhysicianHasRightRolesForViewRecords,
    isNonPhysicianHasRightRolesForViewRecords,
  } = usePatientPermissions({
    patientDetails,
    currentUser,
    isPatientDetailsLoading,
  });

  const markAsCompleted = useCallback(async () => {
    await fetchInboxProviderResolutionEvent(ResolutionEnum.COMPLETED, eventId);
    await queryClient.invalidateQueries({
      queryKey: ['inbox-providers-events'],
    });
    snackbar(
      `'${EVENTS_CATEGORY_DROPDOWN.RECORD_UPDATE_RECEIVED}' for ${patientName} will be marked as Completed.`,
      'success'
    );
  }, [eventId, patientName, snackbar]);

  const onViewMedicalRecords = useCallback(async () => {
    if (
      isNonPhysicianHasRightRolesForViewRecords ||
      isPhysicianHasRightRolesForViewRecords
    ) {
      try {
        await markAsCompleted();
        history.push(
          PORTAL_PATIENTS_ROUTES.PATIENT_TAB.concat('/medical-documents')
            .replace(':patientTab', 'connections')
            .replace(':patientUuid', consumerId)
        );
      } catch {
        snackbar('Something went wrong. Please try again later.', 'error');
      }
    } else {
      openAccessDeniedModal(true);
    }
  }, [
    consumerId,
    history,
    markAsCompleted,
    snackbar,
    isNonPhysicianHasRightRolesForViewRecords,
    isPhysicianHasRightRolesForViewRecords,
    openAccessDeniedModal,
  ]);

  const onCloseMedicalRecords = useCallback(async () => {
    try {
      await markAsCompleted();
    } catch {
      snackbar('Something went wrong. Please try again later.', 'error');
    }
  }, [markAsCompleted, snackbar]);

  return (
    <>
      {eventEntityType === TABLE_EVENT_ENUM.PATIENT_MEDICAL_RECORDS_REQUEST ? (
        <Button
          data-testid={`inbox-close-btn-${eventId}`}
          position="section"
          onClick={onCloseMedicalRecords}
          variant="outlined"
          className={classes.customizedBtn}
        >
          Close
        </Button>
      ) : (
        <Button
          data-testid={`inbox-view-btn-${eventId}`}
          position="section"
          onClick={onViewMedicalRecords}
          variant="outlined"
          className={classes.customizedBtn}
        >
          View
        </Button>
      )}
    </>
  );
};
