import React, {FC} from 'react';
import {Box, BoxProps, Tooltip} from '@material-ui/core';
import {ColumnDef} from '@tanstack/react-table';
import styled from 'styled-components';

import {ContentMRN} from '../../../shared/components/contentMRN';
import {dateFormatWithTime} from '../../../shared/constants/dateFormat';
import {AdditionalTableProps} from '../../../shared/interfaces/table';
import {colors} from '../../../shared/styles/theme';
import {dateFormatter, formatUTCDate} from '../../../shared/utils/formatDate';
import {getFullName} from '../../../shared/utils/getFullName';
import {ActionButton} from '../components/actionButton';
import {DismissActionButton} from '../components/dismissActionButton';

import {CATEGORY_FILTERS_LABELS} from './categoryFilters';
import {
  CallBackFuncType,
  COMPLETED_RESOLUTION_ENUM,
  EVENTS_CATEGORY,
  FormattedProviderInboxEventsType,
} from './types';

type CellType = {
  row: {
    original: FormattedProviderInboxEventsType;
  };
};

export const INBOX_TABLE_COLUMNS = [
  {
    id: 'category',
    header: 'Category',
    width: '255px',
    cell(cell: CellType): JSX.Element {
      return (
        <StyledCell
          info={CATEGORY_FILTERS_LABELS[cell.row.original.eventCategory]}
          fontWeight={700}
        />
      );
    },
  },
  {
    id: 'patient',
    header: 'Patient',
    width: '270px',
    cell(cell: CellType): JSX.Element {
      return <StyledCell info={cell.row.original.patient} />;
    },
  },
  {
    id: 'MRN',
    header: 'MRN',
    width: '250px',
    cell(cell: CellType): JSX.Element {
      return (
        <ContentMRN
          MRNData={cell.row.original.medicalRecordNumbers}
          id={cell.row.original.id}
        />
      );
    },
  },
  {
    id: 'date_of_birth',
    header: 'Date of Birth',
    width: '145px',
    cell(cell: CellType): JSX.Element {
      return (
        <StyledCell
          info={dateFormatter(cell.row.original.patientDateOfBirth)}
        />
      );
    },
  },
  {
    id: 'description',
    header: 'Description',
    width: '450px',
    cell(cell: CellType): JSX.Element {
      return <StyledCell info={cell.row.original.eventCategoryDescription} />;
    },
  },
  {
    id: 'date',
    header: 'Date & Time',
    width: '200px',
    cell(cell: CellType): JSX.Element {
      return (
        <StyledCell
          info={formatUTCDate(cell.row.original.createdAt, dateFormatWithTime)}
          color={colors.grey801}
        />
      );
    },
  },
];

const COMPLETED_TABLE_ADDITIONAL_COLUMN = [
  {
    id: 'completed_by',
    header: 'Status',
    width: '300px',
    cell(cell: CellType): JSX.Element {
      const {
        completedResolution,
        completedByClientFirstName,
        completedByClientLastName,
      } = cell.row.original;
      const isCompleted =
        completedResolution === COMPLETED_RESOLUTION_ENUM.COMPLETED ||
        completedResolution ===
          COMPLETED_RESOLUTION_ENUM.COMPLETED_MEDICAL_RECORDS_UPLOADED ||
        completedResolution ===
          COMPLETED_RESOLUTION_ENUM.COMPLETED_NO_MEDICAL_RECORDS_TO_UPLOAD;

      const startText = isCompleted ? 'Completed by' : 'Dismissed by';

      const clientName = getFullName(
        completedByClientFirstName ?? '',
        completedByClientLastName ?? ''
      );

      return (
        <CompletedDescriptionWrapper>
          <Tooltip title={`${startText} ${clientName}`}>
            <StyledTextBox
              color={isCompleted ? colors.primary : colors.grey801}
              fontWeight={600}
            >{`${startText} ${clientName}`}</StyledTextBox>
          </Tooltip>
          <Box color={colors.grey801}>
            {formatUTCDate(cell.row.original.completedAt, dateFormatWithTime)}
          </Box>
        </CompletedDescriptionWrapper>
      );
    },
  },
];

const getToDoTableActionColumn = (
  resolutionActionCallBack: CallBackFuncType
): (ColumnDef<FormattedProviderInboxEventsType> & AdditionalTableProps)[] => [
  {
    id: 'actions',
    width: '240px',
    isStickyRight: true,
    header: 'Actions',
    cell(cell: CellType): JSX.Element {
      const {
        patientFirstName,
        patientLastName,
        eventCategory,
        id,
        eventEntityId,
        eventEntityRelyingPartyId,
        consumerUuid,
        eventEntityClientId,
        eventEntityType,
      } = cell.row.original;

      const isShowDismissButton =
        eventCategory !== EVENTS_CATEGORY.PATIENT_REQUESTED_DOCUMENT_UPLOAD &&
        eventCategory !== EVENTS_CATEGORY.PATIENT_REQUESTED_DOCUMENT_UPDATE &&
        eventCategory !==
          EVENTS_CATEGORY.PATIENT_REQUESTED_TEXT_DOCUMENT_UPLOAD &&
        eventCategory !==
          EVENTS_CATEGORY.PATIENT_REQUESTED_TEXT_DOCUMENT_UPDATE &&
        eventCategory !==
          EVENTS_CATEGORY.PATIENT_REQUESTED_IMAGE_DOCUMENT_UPLOAD &&
        eventCategory !==
          EVENTS_CATEGORY.PATIENT_REQUESTED_IMAGE_DOCUMENT_UPDATE &&
        eventCategory !==
          EVENTS_CATEGORY.PATIENT_REQUESTED_BILLING_DOCUMENT_UPLOAD &&
        eventCategory !==
          EVENTS_CATEGORY.PATIENT_REQUESTED_BILLING_DOCUMENT_UPDATE;

      return (
        <ActionsWrapper>
          {isShowDismissButton ? (
            <DismissActionButton
              onClickCallBack={resolutionActionCallBack}
              eventId={id}
              patientName={getFullName(patientFirstName, patientLastName)}
              eventCategory={CATEGORY_FILTERS_LABELS[eventCategory]}
            />
          ) : null}
          <ActionButton
            eventId={id}
            invitationId={eventEntityId}
            eventCategory={eventCategory}
            relyingPartyId={eventEntityRelyingPartyId}
            consumerId={consumerUuid}
            patientFirstname={patientFirstName}
            clientId={eventEntityClientId}
            onClickCallBack={resolutionActionCallBack}
            eventEntityType={eventEntityType}
          />
        </ActionsWrapper>
      );
    },
  },
];

export const getToDoTableColumns = (
  resolutionActionCallback: CallBackFuncType
): (ColumnDef<FormattedProviderInboxEventsType> & AdditionalTableProps)[] => [
  ...INBOX_TABLE_COLUMNS,
  ...getToDoTableActionColumn(resolutionActionCallback),
];

export const COMPLETED_TABLE_COLUMNS = [
  ...INBOX_TABLE_COLUMNS,
  ...COMPLETED_TABLE_ADDITIONAL_COLUMN,
];

const StyledBox = styled(Box)`
  word-wrap: break-word;
`;

const StyledCell: FC<BoxProps & {info: string | null | JSX.Element}> = ({
  info,
  ...otherProps
}) => <StyledBox {...otherProps}>{info}</StyledBox>;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  justify-content: flex-end;
`;

const CompletedDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledTextBox = styled(Box)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: inherit;
`;
