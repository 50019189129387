import React, {useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';

import {currentUserState} from '../../../../shared/atoms/authAtom';
import {Button} from '../../../../shared/components/button';
import {useErrorHandling} from '../../../../shared/hooks/useErrorHandling';
import {colors} from '../../../../shared/styles/theme';
import {capitalizeParser} from '../../../../shared/utils/captalizeParser';
import {
  ConnectionNode,
  ProviderDetailsItem,
} from '../../constants/providerDetailsItem';
import {usePatientLimitedDetails} from '../../hooks/usePatientLimitedDetails';
import {createPatientConnection} from '../../utils/fetchers';
import {getOrderRecordsAddressConnection} from '../../utils/getProviderDirectoryAddress';

import {EditablePreferences} from './editablePreferences';

interface ConnectionPreferencesProps {
  provider: ProviderDetailsItem;
  onNext: () => void;
  onBack: () => void;
  isOrganization?: boolean;
  location: ConnectionNode;
}

export const ConnectionPreferences: React.FC<ConnectionPreferencesProps> = ({
  onNext,
  onBack,
  provider,
  isOrganization = false,
  location,
}) => {
  const history = useHistory();
  const {patientUuid} = useParams<{patientUuid: string}>();
  const currentUser = useRecoilValue(currentUserState);
  const {data: patientDetails} = usePatientLimitedDetails(
    patientUuid,
    currentUser?.relyingParty.id ?? null
  );

  const handleError = useErrorHandling();
  const address = provider.addresses
    ? getOrderRecordsAddressConnection(location)
    : undefined;
  const [receiveRecords, setReceiveRecords] = useState(true);
  const [shareRecords, setShareRecords] = useState(true);

  const handleRequest = async () => {
    try {
      const response = await createPatientConnection(patientUuid, {
        ...provider,
        location: {
          external_id: location?.location_external_id,
          name: location?.location_name,
          organization_name: location?.location_organization_name,
          address_1: location?.location_address1,
          address_2: location?.location_address2,
          city: location?.location_city,
          district: location?.location_district,
          state: location?.location_state,
          postal_code: location?.location_postal_code,
          country: location?.location_country,
          telecoms: location?.location_telecoms,
        },
        receiveRecords,
        share_provider_to_patient: receiveRecords,
        share_patient_to_provider: shareRecords,
        provider_npi_type: provider.provider_type,
        relying_party_id: location.relying_party_id,
        patient_first_name: patientDetails?.publicDetails.firstName,
        issuer_id: location?.relying_party_issuer_id,
        client_id: location?.client_id,
      });
      history.push({
        state: {
          rowData: {...response, provider_directory_location: location},
          consumerUuid: patientUuid,
        },
      });
      onNext();
    } catch (error) {
      if (error instanceof Error) {
        return handleError(error);
      }
    }
  };

  return (
    <Container>
      <Title>Connection Preferences</Title>
      <SubTitle>Please select the preferences for the patient.</SubTitle>
      <TextGroup>
        {capitalizeParser(provider.provider_name) && (
          <TitleText>
            {capitalizeParser(provider.provider_name)}
            {!isOrganization && ' & Associates'}
          </TitleText>
        )}
        {address && <DescriptionText>{address}</DescriptionText>}
      </TextGroup>
      <Header>Set Preferences</Header>

      <Preferences>
        <EditablePreferences
          receiveRecords={receiveRecords}
          setReceiveRecords={setReceiveRecords}
          shareRecords={shareRecords}
          setShareRecords={setShareRecords}
          noBackground
        />
      </Preferences>
      <ButtonContainer>
        <Button variant="outlined" onClick={onBack}>
          Back
        </Button>
        <Button disabled={false} onClick={handleRequest} type="submit">
          Save
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const Header = styled.div`
  font-size: 14px;
  font-weight: 700;
  padding: 20px 0px;
`;

const Preferences = styled.div`
  width: 464px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  margin-top: 40px;
  display: flex;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
`;

const SubTitle = styled.p`
  color: ${colors.grey801};
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  margin-bottom: 24px;
`;

const TextGroup = styled.div`
  height: 77px;
  width: 432px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border: 1px solid ${colors.grey1100};
  border-radius: 4px;
`;

const TitleText = styled.text`
  font-size: 16px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  line-height: 24px;
  color: ${colors.black};
`;

const DescriptionText = styled.text`
  font-size: 14px;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  line-height: 21px;
  color: ${colors.black};
`;
