import React, {FC, useCallback, useMemo} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import styled from 'styled-components';

import BlueCheckSquare from '../../../../../assets/icons/blueCheckSquare.svg';
import SuccessBlueSquareIcon from '../../../../../assets/icons/successBlueSquare.svg';
import {Button, CancelButton} from '../../../../shared/components/button';
import {colors} from '../../../../shared/styles/theme';
import {MainHeader} from '../../../layout/components/mainHeader';
import {chooseProviderDisplayNames} from '../../utils/chooseProviderName';
import {getProviderDirectoryAddress} from '../../utils/getProviderDirectoryAddress';

import {OrderRecordItem} from './orderRecordItem';

type Props = {
  title: string;
  description: string;
  selectedIndex: string[] | null;
  onSelectIndex: (index: string) => void;
  onNext: () => void;
  hasSkipButton?: boolean;
};

enum ProviderTypes {
  ORGANIZATION = 'ORGANIZATION',
  EXTERNAL_ORGANIZATION = 'EXTERNAL_ORGANIZATION',
}

export const OrderRecordsOptions: FC<Props> = ({
  title,
  description,
  selectedIndex,
  onSelectIndex,
  onNext,
  hasSkipButton = false,
}) => {
  const location = useLocation();
  const {rowData} = location.state as {rowData: any};
  const isIndividual = useMemo(
    () =>
      rowData?.relying_party_provider_type !==
        ProviderTypes.EXTERNAL_ORGANIZATION &&
      rowData?.relying_party_provider_type !== ProviderTypes.ORGANIZATION &&
      rowData?.client_last_name &&
      rowData?.client_last_name?.length > 0,
    [rowData?.client_last_name, rowData?.relying_party_provider_type]
  );
  const {nameDisplay, organizationDisplay} = chooseProviderDisplayNames(
    rowData.client_first_name ? rowData : rowData.provider_directory_location
  );

  const {firstLine, secondLine} = getProviderDirectoryAddress(
    rowData.provider_directory_location
  );
  const address = `${firstLine ?? ''} ${secondLine ?? ''}`;

  const history = useHistory();

  const handleGoBack = useCallback(async () => {
    history.goBack();
  }, [history]);

  const handleSkip = useCallback(async () => {
    history.replace(
      `/portal-patients/connections/${rowData.consumer_uuid}/order-records`
    );
  }, [history, rowData.consumer_uuid]);

  return (
    <Container>
      <MainHeader title={title} description={description} />
      <TextGroup>
        {nameDisplay && (
          <TitleText>
            {nameDisplay}
            {isIndividual && ' & Associates'}
          </TitleText>
        )}
        {organizationDisplay && (
          <DescriptionText>{organizationDisplay}</DescriptionText>
        )}
        {address && <DescriptionText>{address}</DescriptionText>}
      </TextGroup>
      <SubHeader>Select Type of Records</SubHeader>
      <OrderRecordItem
        iconSrc={BlueCheckSquare}
        iconSelectedSrc={SuccessBlueSquareIcon}
        title="Medical Records"
        description="Medical Records are your reports, conditions, prescriptions, lab results medical history, etc."
        selected={selectedIndex?.includes('TEXT')}
        onClick={() => onSelectIndex('TEXT')}
      />
      <OrderRecordItem
        iconSrc={BlueCheckSquare}
        iconSelectedSrc={SuccessBlueSquareIcon}
        title="Images"
        description="Images are your ultrasounds, x-rays, MRIs, and other types of imaging."
        selected={selectedIndex?.includes('IMAGE')}
        onClick={() => onSelectIndex('IMAGE')}
      />
      <OrderRecordItem
        iconSrc={BlueCheckSquare}
        iconSelectedSrc={SuccessBlueSquareIcon}
        title="Billing Records"
        description="Billing Records are your statements and invoices."
        selected={selectedIndex?.includes('BILLING_RECORDS')}
        onClick={() => onSelectIndex('BILLING_RECORDS')}
      />
      <ButtonWrapper>
        {hasSkipButton ? (
          <Button variant="outlined" onClick={handleSkip}>
            Skip
          </Button>
        ) : (
          <CancelButton onClick={handleGoBack} />
        )}
        <Button disabled={selectedIndex?.length === 0} onClick={onNext}>
          Next
        </Button>
      </ButtonWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;
`;

const SubHeader = styled.span`
  font-size: 14px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  line-height: 24px;
  color: ${colors.black};
  padding: 4px 0;
`;

const TextGroup = styled.div`
  height: auto;
  width: 430px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 16px 16px 16px;
  border: 1px solid ${colors.grey1100};
  border-radius: 4px;
`;

const TitleText = styled.span`
  font-size: 16px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  line-height: 24px;
  color: ${colors.black};
`;

const DescriptionText = styled.span`
  font-size: 14px;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  line-height: 21px;
  color: ${colors.black};
`;

const ButtonWrapper = styled.div`
  margin-top: 24px;
`;
