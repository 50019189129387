import {DateRangeType} from '../../../shared/components/datePicker/constants/types';
import {
  FormattedMRNsDetailsType,
  MRNDetailsType,
} from '../../../shared/constants/formattedMRNsDetailsType';

export enum EVENTS_CATEGORY {
  PATIENT_DENIED_CONNECTION_REQUEST = 'PATIENT_DENIED_CONNECTION_REQUEST',
  PATIENT_CREATE_CONNECTION_REQUEST_EXPIRED = 'PATIENT_CREATE_CONNECTION_REQUEST_EXPIRED',
  PATIENT_INVITATION_EXPIRED = 'PATIENT_INVITATION_EXPIRED',
  PATIENT_DISCONNECTED = 'PATIENT_DISCONNECTED',
  PATIENT_DENIED_SHARE_REQUEST = 'PATIENT_DENIED_SHARE_REQUEST',
  PATIENT_CHANGE_CONNECTION_REQUEST_EXPIRED = 'PATIENT_CHANGE_CONNECTION_REQUEST_EXPIRED',
  PATIENT_REQUESTED_DOCUMENT_UPLOAD = 'PATIENT_REQUESTED_DOCUMENT_UPLOAD',
  PATIENT_REQUESTED_DOCUMENT_UPDATE = 'PATIENT_REQUESTED_DOCUMENT_UPDATE',
  PATIENT_REQUESTED_TEXT_DOCUMENT_UPLOAD = 'PATIENT_REQUESTED_TEXT_DOCUMENT_UPLOAD',
  PATIENT_REQUESTED_TEXT_DOCUMENT_UPDATE = 'PATIENT_REQUESTED_TEXT_DOCUMENT_UPDATE',
  PATIENT_REQUESTED_IMAGE_DOCUMENT_UPLOAD = 'PATIENT_REQUESTED_IMAGE_DOCUMENT_UPLOAD',
  PATIENT_REQUESTED_IMAGE_DOCUMENT_UPDATE = 'PATIENT_REQUESTED_IMAGE_DOCUMENT_UPDATE',
  PATIENT_REQUESTED_BILLING_DOCUMENT_UPLOAD = 'PATIENT_REQUESTED_BILLING_DOCUMENT_UPLOAD',
  PATIENT_REQUESTED_BILLING_DOCUMENT_UPDATE = 'PATIENT_REQUESTED_BILLING_DOCUMENT_UPDATE',
  PATIENT_RECORD_UPDATE_RECEIVED = 'PATIENT_RECORD_UPDATE_RECEIVED',
}

export enum EVENTS_CATEGORY_DROPDOWN {
  CONNECTION_DENIED = 'Connection Denied',
  CONNECTION_REQUEST_EXPIRED = 'Connection Request Expired',
  INVITATION_EXPIRED = 'Invitation Expired',
  DISCONNECTED = 'Patient Disconnected',
  SHARING_DENIED = 'Sharing Denied',
  SHARING_REQUEST_EXPIRED = 'Sharing Request Expired',
  UPLOAD_IMAGES = 'Upload Images',
  UPLOAD_TEXT_RECORDS = 'Upload Medical Records',
  UPLOAD_BILLING_RECORDS = 'Upload Billing Records',
  RECORD_UPDATE_RECEIVED = 'Order Completed',
}

export type PatientsNamesType = {
  first_name: string;
  last_name: string;
};

export type RemappedPatientsNamesType = {
  label: string;
};

export type RemappedPhysiciansType = {
  label: string;
  id: number;
};

export enum TABLE_TYPE_ENUM {
  TO_DO = 'TODO',
  COMPLETED = 'COMPLETED',
}

export enum TABLE_EVENT_ENUM {
  PATIENT_INVITATION = 'PATIENT_INVITATION',
  PATIENT_CONNECTION_REQUEST = 'PATIENT_CONNECTION_REQUEST',
  PATIENT_CONNECTION = 'PATIENT_CONNECTION',
  PATIENT_MEDICAL_RECORDS_REQUEST = 'PATIENT_MEDICAL_RECORDS_REQUEST',
  RELYING_PARTY = 'RELYING_PARTY',
}

export enum COMPLETED_RESOLUTION_ENUM {
  DISMISSED = 'DISMISSED',
  COMPLETED = 'COMPLETED',
  COMPLETED_MEDICAL_RECORDS_UPLOADED = 'COMPLETED_MEDICAL_RECORDS_UPLOADED',
  COMPLETED_NO_MEDICAL_RECORDS_TO_UPLOAD = 'COMPLETED_NO_MEDICAL_RECORDS_TO_UPLOAD',
}

export type ProviderInboxEventsType = {
  id: string;
  status: TABLE_TYPE_ENUM;
  event_category: EVENTS_CATEGORY;
  event_details: {
    patient_comment?: null | string;
    date_from?: null | Date;
    date_to?: null | Date;
    is_maximum_date_range?: boolean;
  } | null;
  consumer_uuid: string | null;
  patient_first_name: string;
  patient_last_name: string;
  patient_date_of_birth: Date | null;
  event_entity_type: TABLE_EVENT_ENUM;
  event_entity_id: number;
  event_entity_relying_party_id: number;
  event_entity_client_id: number;
  completed_resolution: COMPLETED_RESOLUTION_ENUM | null;
  completed_by_client_id: number | null;
  completed_by_client_first_name: string | null;
  completed_by_client_last_name: string | null;
  completed_at: Date | null;
  created_at: Date;
  mrn_ehr_responses: MRNDetailsType[];
};

export type FormattedProviderInboxEventsType = {
  id: string;
  status: TABLE_TYPE_ENUM;
  eventCategory: EVENTS_CATEGORY;
  eventCategoryDescription: string | JSX.Element;
  consumerUuid: string;
  patient: JSX.Element;
  patientFirstName: string;
  patientLastName: string;
  patientDateOfBirth: Date | null;
  eventEntityType: TABLE_EVENT_ENUM;
  eventEntityId: number;
  eventEntityRelyingPartyId: number;
  eventEntityClientId: number;
  completedResolution: COMPLETED_RESOLUTION_ENUM | null;
  completedByClientId: number | null;
  completedByClientFirstName: string | null;
  completedByClientLastName: string | null;
  completedAt: Date | null;
  createdAt: Date;
  medicalRecordNumbers: FormattedMRNsDetailsType[];
};

export type ProviderInboxEventsResponseType = {
  count: number;
  events: ProviderInboxEventsType[];
};

export type filterValuesType = {
  status: TABLE_TYPE_ENUM;
  eventCategories: EVENTS_CATEGORY_DROPDOWN[];
  provider: null | RemappedPhysiciansType;
  patientNames: RemappedPatientsNamesType[];
  dateRange: DateRangeType;
};

export type filterValuesForRequestType = {
  status: TABLE_TYPE_ENUM;
  event_categories: EVENTS_CATEGORY[];
  client_ids: number[];
  patient_names: PatientsNamesType[];
  from_date: string | null;
  to_date: string | null;
};

export enum ResolutionEnum {
  DISMISSED = 'DISMISSED',
  COMPLETED = 'COMPLETED',
  COMPLETED_MEDICAL_RECORDS_UPLOADED = 'COMPLETED_MEDICAL_RECORDS_UPLOADED',
  COMPLETED_NO_MEDICAL_RECORDS_TO_UPLOAD = 'COMPLETED_NO_MEDICAL_RECORDS_TO_UPLOAD',
}

export type CallBackFuncType = (callback: () => Promise<void>) => void;

type WordingRequestRecordsType = {
  title: string;
  description: string;
  formTitle: string;
  isRecordsCheckBoxTitle: string;
  isRecordsCheckBoxDescription: string;
  noRecordsCheckBoxTitle: string;
  noRecordsCheckBoxDescription: string;
};

export type WordingsRequestRecordsType = {
  [key: string]: WordingRequestRecordsType;
};

export type RequestRecordsFormValuesTypes = {
  areMedicalRecordNumbersExist: boolean;
  isRequestHaveMedicalRecords: 'have' | 'do-not-have';
  haveMedicalRecordsEhrId: string;
  haveMedicalRecordsMrn: string;
  doNotHaveMedicalRecordsEhrId: string;
  doNotHaveMedicalRecordsMrn: string;
};
