import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Redirect, useLocation, useParams} from 'react-router-dom';
import {Typography} from '@material-ui/core';
import {Formik} from 'formik';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';
import * as yup from 'yup';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {Description} from '../../../shared/components/description';
import {ExitBar} from '../../../shared/components/exitBar';
import {LoadingBar} from '../../../shared/components/loadingBar';
import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {RequestRecordsForm} from '../../inbox/components/requestRecordsForm';
import {EVENTS_CATEGORY, ResolutionEnum} from '../../inbox/constants/types';
import {WORDINGS_REQUEST_RECORDS_TYPE} from '../../inbox/constants/wordings';
import {useRequestedRecordInfo} from '../../inbox/hooks/useGetRequestedRecordInfo';
import {fetchInboxProviderResolutionEvent} from '../../inbox/utils/fetchers';
import {PORTAL_PATIENTS_ROUTES} from '../constants/routes';
import {useEHRs} from '../hooks/useEHRs';
import {useMRNs} from '../hooks/useMRNs';
import {usePatientLimitedDetails} from '../hooks/usePatientLimitedDetails';

import {UploadRecordsHeader} from './uploadRecordsHeader';

const validationSchema = () =>
  yup.object().shape({
    haveMedicalRecordsEhrId: yup
      .string()
      .when(['isRequestHaveMedicalRecords', 'areMedicalRecordNumbersExist'], {
        is: (
          isRequestHaveMedicalRecords: 'have' | 'do-not-have',
          areMedicalRecordNumbersExist: boolean
        ) =>
          isRequestHaveMedicalRecords === 'have' &&
          !areMedicalRecordNumbersExist,
        then: yup
          .string()
          .trim()
          .required('EHR is required')
          .notOneOf(['default'], 'EHR is required field'),
      }),
    haveMedicalRecordsMrn: yup
      .string()
      .when(['isRequestHaveMedicalRecords', 'areMedicalRecordNumbersExist'], {
        is: (
          isRequestHaveMedicalRecords: 'have' | 'do-not-have',
          areMedicalRecordNumbersExist: boolean
        ) =>
          isRequestHaveMedicalRecords === 'have' &&
          !areMedicalRecordNumbersExist,
        then: yup.string().trim().required('MRN is required field'),
      }),
  });

type FormValuesTypes = {
  areMedicalRecordNumbersExist: boolean;
  isRequestHaveMedicalRecords: 'have' | 'do-not-have';
  haveMedicalRecordsEhrId: string;
  haveMedicalRecordsMrn: string;
  doNotHaveMedicalRecordsEhrId: string;
  doNotHaveMedicalRecordsMrn: string;
};

export const PatientMedicalRecordRequest: React.FC = () => {
  const [redirectLink, setRedirectLink] = useState<string | null>(null);
  const snackbar = useCustomizedSnackbar();
  const {search} = useLocation();
  const currentUser = useRecoilValue(currentUserState);
  const {patientUuid} = useParams<{patientUuid: string}>();
  const {data: patientDetails, isLoading: isPatientDetailsLoading} =
    usePatientLimitedDetails(patientUuid, currentUser?.relyingParty.id ?? null);
  const {EHRs, EHRsIsLoading, EHRsHasError, getEHRs} = useEHRs();
  const {addMRNisLoading, onAddMRN} = useMRNs();

  const redirectToInbox = useCallback(() => {
    setRedirectLink('/inbox/to-do');
  }, []);

  const getEventId = useCallback((): string => {
    const eventId = new URLSearchParams(search).get('event-id');

    return eventId ?? '';
  }, [search]);

  const {
    requestedRecordType,
    requestedRecordTypeIsLoading,
    eventDetails,
    isError,
  } = useRequestedRecordInfo(getEventId());

  const areMedicalRecordNumbersExist = Boolean(
    patientDetails?.mrnDetails.length
  );

  const fromInitialValues: FormValuesTypes = {
    areMedicalRecordNumbersExist,
    isRequestHaveMedicalRecords: 'have',
    haveMedicalRecordsEhrId: 'default',
    haveMedicalRecordsMrn: '',
    doNotHaveMedicalRecordsEhrId: 'default',
    doNotHaveMedicalRecordsMrn: '',
  };

  const isAllowedPatientMedicalRecordRequest =
    !isPatientDetailsLoading &&
    patientDetails &&
    requestedRecordType &&
    !requestedRecordTypeIsLoading;

  const onCreateMrn = useCallback(
    async (ehrId: string, mrn: string, cb: () => void) => {
      const ehr = EHRs.find(({id}) => ehrId === id);

      if (ehr) {
        await onAddMRN(patientUuid, ehr, mrn, cb);
      }
    },
    [EHRs, onAddMRN, patientUuid]
  );

  const requestedRecordsTypeValue: string = useMemo(() => {
    switch (requestedRecordType) {
      case EVENTS_CATEGORY.PATIENT_REQUESTED_TEXT_DOCUMENT_UPLOAD:
      case EVENTS_CATEGORY.PATIENT_REQUESTED_TEXT_DOCUMENT_UPDATE:
        return 'text-records';
      case EVENTS_CATEGORY.PATIENT_REQUESTED_IMAGE_DOCUMENT_UPLOAD:
      case EVENTS_CATEGORY.PATIENT_REQUESTED_IMAGE_DOCUMENT_UPDATE:
        return 'images';
      case EVENTS_CATEGORY.PATIENT_REQUESTED_BILLING_DOCUMENT_UPLOAD:
      case EVENTS_CATEGORY.PATIENT_REQUESTED_BILLING_DOCUMENT_UPDATE:
        return 'billing-records';
      default:
        return 'medical-records';
    }
  }, [requestedRecordType]);

  const redirectToUploadMedicalRecords = useCallback(
    (eventId: string) => {
      setRedirectLink(
        PORTAL_PATIENTS_ROUTES.UPLOAD_MEDICAL_RECORDS_DATA.replace(
          ':patientUuid',
          patientUuid
        ).concat(`?event-id=${eventId}`)
      );
    },
    [patientUuid]
  );

  const onConfirmMedicalRecordsExistAndRequestHaveMedicalRecords =
    useCallback(() => {
      try {
        redirectToUploadMedicalRecords(getEventId());
      } catch (e) {
        snackbar('Something went wrong. Please try again later.', 'error');
      }
    }, [getEventId, redirectToUploadMedicalRecords, snackbar]);
  const onConfirmMedicalRecordsExistAndRequestDoNotHaveMedicalRecords =
    useCallback(async () => {
      try {
        await fetchInboxProviderResolutionEvent(
          ResolutionEnum.COMPLETED_NO_MEDICAL_RECORDS_TO_UPLOAD,
          getEventId()
        );
        snackbar('Response sent to the patient', 'success');
        redirectToInbox();
      } catch (e) {
        snackbar('Something went wrong. Please try again later.', 'error');
      }
    }, [getEventId, redirectToInbox, snackbar]);

  const onConfirmMedicalRecordsDoNotExistAndRequestHasMedicalRecords =
    useCallback(
      async (
        haveMedicalRecordsEhrId: FormValuesTypes['haveMedicalRecordsEhrId'],
        haveMedicalRecordsMrn: FormValuesTypes['haveMedicalRecordsMrn']
      ) => {
        try {
          if (!haveMedicalRecordsEhrId || !haveMedicalRecordsMrn) {
            throw new Error('EHR and MRN are required fields');
          }

          await onCreateMrn(
            haveMedicalRecordsEhrId,
            haveMedicalRecordsMrn,
            () => {
              redirectToUploadMedicalRecords(getEventId());
            }
          );
        } catch (e) {
          snackbar('Something went wrong. Please try again later.', 'error');
        }
      },
      [getEventId, onCreateMrn, redirectToUploadMedicalRecords, snackbar]
    );

  const onConfirmMedicalRecordsDoNotExistAndRequestDoNotHaveMedicalRecords =
    useCallback(
      async (
        doNotHaveMedicalRecordsEhrId: FormValuesTypes['doNotHaveMedicalRecordsEhrId'],
        doNotHaveMedicalRecordsMrn: FormValuesTypes['doNotHaveMedicalRecordsMrn']
      ) => {
        try {
          const onResolveEvent = async () => {
            await fetchInboxProviderResolutionEvent(
              ResolutionEnum.COMPLETED_NO_MEDICAL_RECORDS_TO_UPLOAD,
              getEventId()
            );
            snackbar('Response sent to the patient', 'success');
            redirectToInbox();
          };

          if (doNotHaveMedicalRecordsEhrId && doNotHaveMedicalRecordsMrn) {
            await onCreateMrn(
              doNotHaveMedicalRecordsEhrId,
              doNotHaveMedicalRecordsMrn,
              onResolveEvent
            );
          } else {
            await onResolveEvent();
          }
        } catch (e) {
          snackbar('Something went wrong. Please try again later.', 'error');
        }
      },
      [getEventId, onCreateMrn, redirectToInbox, snackbar]
    );

  const onConfirm = useCallback(
    async ({
      areMedicalRecordNumbersExist,
      isRequestHaveMedicalRecords,
      haveMedicalRecordsEhrId,
      haveMedicalRecordsMrn,
      doNotHaveMedicalRecordsEhrId,
      doNotHaveMedicalRecordsMrn,
    }: FormValuesTypes) => {
      const medicalRecordsExistAndRequestHaveMedicalRecords =
        areMedicalRecordNumbersExist && isRequestHaveMedicalRecords === 'have';

      const medicalRecordsExistAndRequestDoNotHaveMedicalRecords =
        areMedicalRecordNumbersExist &&
        isRequestHaveMedicalRecords === 'do-not-have';

      const medicalRecordsDoNotExistAndRequestHasMedicalRecords =
        !areMedicalRecordNumbersExist && isRequestHaveMedicalRecords === 'have';

      const medicalRecordsDoNotExistAndRequestDoNotHaveMedicalRecords =
        !areMedicalRecordNumbersExist &&
        isRequestHaveMedicalRecords === 'do-not-have';

      if (medicalRecordsExistAndRequestHaveMedicalRecords) {
        onConfirmMedicalRecordsExistAndRequestHaveMedicalRecords();
      } else if (medicalRecordsExistAndRequestDoNotHaveMedicalRecords) {
        await onConfirmMedicalRecordsExistAndRequestDoNotHaveMedicalRecords();
      } else if (medicalRecordsDoNotExistAndRequestHasMedicalRecords) {
        await onConfirmMedicalRecordsDoNotExistAndRequestHasMedicalRecords(
          haveMedicalRecordsEhrId,
          haveMedicalRecordsMrn
        );
      } else if (medicalRecordsDoNotExistAndRequestDoNotHaveMedicalRecords) {
        await onConfirmMedicalRecordsDoNotExistAndRequestDoNotHaveMedicalRecords(
          doNotHaveMedicalRecordsEhrId,
          doNotHaveMedicalRecordsMrn
        );
      }
    },
    [
      onConfirmMedicalRecordsDoNotExistAndRequestDoNotHaveMedicalRecords,
      onConfirmMedicalRecordsDoNotExistAndRequestHasMedicalRecords,
      onConfirmMedicalRecordsExistAndRequestDoNotHaveMedicalRecords,
      onConfirmMedicalRecordsExistAndRequestHaveMedicalRecords,
    ]
  );

  useEffect(() => {
    if (currentUser?.relyingParty.id) {
      getEHRs(currentUser.relyingParty.id);
    }
  }, [getEHRs, currentUser?.relyingParty.id]);

  if (redirectLink || !getEventId() || isError) {
    return <Redirect to={redirectLink ?? '/inbox/to-do'} />;
  }

  return (
    <Container>
      <div>
        <LoadingBar
          loading={
            (isPatientDetailsLoading && !patientDetails) ||
            (requestedRecordTypeIsLoading && !requestedRecordType)
          }
        />
      </div>

      {isAllowedPatientMedicalRecordRequest ? (
        <>
          <ExitBar
            onClick={redirectToInbox}
            title={
              WORDINGS_REQUEST_RECORDS_TYPE[requestedRecordsTypeValue].title
            }
            testID="exit-btn"
          />
          <Body>
            <Description
              title={
                WORDINGS_REQUEST_RECORDS_TYPE[requestedRecordsTypeValue].title
              }
              description={
                WORDINGS_REQUEST_RECORDS_TYPE[requestedRecordsTypeValue]
                  .description
              }
            />
            <UploadRecordsHeader
              patientsProfileDetails={patientDetails}
              eventDetails={eventDetails}
            />
            <SubTitle>
              {
                WORDINGS_REQUEST_RECORDS_TYPE[requestedRecordsTypeValue]
                  .formTitle
              }
            </SubTitle>
            <Formik
              initialErrors={{
                isRequestHaveMedicalRecords: '',
                haveMedicalRecordsEhrId: '',
                haveMedicalRecordsMrn: '',
                doNotHaveMedicalRecordsEhrId: '',
                doNotHaveMedicalRecordsMrn: '',
              }}
              validateOnMount
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={fromInitialValues}
              onSubmit={onConfirm}
            >
              {({values, ...formik}) => (
                <RequestRecordsForm
                  formik={formik}
                  values={values}
                  EHRs={EHRs}
                  EHRsIsLoading={EHRsIsLoading}
                  EHRsHasError={EHRsHasError}
                  redirectToInbox={redirectToInbox}
                  addMRNisLoading={addMRNisLoading}
                  wordings={WORDINGS_REQUEST_RECORDS_TYPE}
                  requestedRecordsType={requestedRecordsTypeValue}
                />
              )}
            </Formik>
          </Body>
        </>
      ) : null}
    </Container>
  );
};

const Body = styled.div`
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 60px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
`;

const SubTitle = styled(Typography)`
  font-weight: bold;
`;
