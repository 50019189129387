import React, {FC, useMemo} from 'react';
import {getCoreRowModel, useReactTable} from '@tanstack/react-table';
import {useRecoilValue} from 'recoil';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {RedirectToFirstAvailableRoute} from '../../../shared/components/redirectToFirstAvailableRoute';
import {PlaceholderComponent} from '../../../shared/components/table/placeholderComponent';
import {StyledTable} from '../../../shared/components/table/styledTable';
import {ROLES} from '../../../shared/constants/roles';
import {PatientNameClickModalWindowsProvider} from '../../../shared/providers/patientNameClickModalWindowsProvider';
import {isRoleMatched} from '../../../shared/utils/user';
import {usePagination} from '../../portalPatients/components/table/hooks/usePagination';
import {getTotalPages} from '../../portalPatients/utils/getTotalPages';
import {FiltersWrapper} from '../components/filtersWrapper';
import {InboxWrapper} from '../components/inboxWrapper';
import {NotValidTaskModalWindow} from '../components/notValidTaskModalWindow';
import {getDefaultToDoFiltersValues} from '../constants/defaultFilterValues';
import {getToDoTableColumns} from '../constants/inboxTableConfig';
import {TAB_INDEX} from '../constants/tabIndex';
import {useInboxFilters} from '../hooks/useInboxFilters';
import {useInboxProviderEvents} from '../hooks/useInboxProviderEvents';
import {useResolutionAction} from '../hooks/useResolutionAction';
import {formatInboxEvents} from '../utils/formatInboxEvents';

type Props = {
  title: string;
};

export const InboxToDoPage: FC<Props> = ({title}) => {
  const currentUser = useRecoilValue(currentUserState);

  const {setPagination, pagination, resetPagination, nextPage} =
    usePagination();

  const {
    filterValues,
    setFilterValues,
    handleToggleCategoryValue,
    handleToggleProviderValue,
    handleTogglePatientValue,
    handleToggleDateRange,
    handleToggleExpiredRequests,
    handleClearFilters,
  } = useInboxFilters(
    getDefaultToDoFiltersValues(currentUser),
    resetPagination
  );

  const {
    data: {events: inboxProviderEventsData, count: countOfItems},
    isLoading: inboxProviderEventsLoading,
    isFetching: inboxProviderEventsFetching,
    isFetchedAfterMount: inboxProviderEventsIsFetchedAfterMount,
  } = useInboxProviderEvents(filterValues, pagination, nextPage);

  const {resolutionAction, isResolutionLoading} = useResolutionAction();

  const isRedirect = !isRoleMatched(currentUser?.roles, [
    ROLES.RELYING_PARTY_OWNER,
    ROLES.RELYING_PARTY_ADMIN,
    ROLES.RELYING_PARTY_RECORDS_REQUESTER,
    ROLES.RELYING_PARTY_RECORDS_VIEWER,
    ROLES.RELYING_PARTY_PATIENT_ASSISTANT,
  ]);

  const nothingFoundCase =
    !inboxProviderEventsLoading && inboxProviderEventsData.length === 0;

  const tableColumns = useMemo(
    () => getToDoTableColumns(resolutionAction),
    [resolutionAction]
  );

  const isLoading =
    ((inboxProviderEventsLoading || inboxProviderEventsFetching) &&
      !inboxProviderEventsIsFetchedAfterMount) ||
    isResolutionLoading;

  const table = useReactTable({
    data: formatInboxEvents(inboxProviderEventsData),
    columns: tableColumns,
    pageCount: getTotalPages(countOfItems),
    state: {
      pagination,
    },
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    manualPagination: true,
  });

  return (
    <>
      {isRedirect && <RedirectToFirstAvailableRoute />}
      <PatientNameClickModalWindowsProvider>
        <InboxWrapper
          title={title}
          handleToggleExpiredRequests={handleToggleExpiredRequests}
          isLoading={isLoading}
          activeTabIndex={TAB_INDEX.FIRST}
          categoryEvents={filterValues.eventCategories}
          filterValues={filterValues}
          setSelectedFilterValues={setFilterValues}
          resetPagination={resetPagination}
        >
          <FiltersWrapper
            filterValues={filterValues}
            handleToggleCategoryValue={handleToggleCategoryValue}
            handleToggleProviderValue={handleToggleProviderValue}
            handleTogglePatientValue={handleTogglePatientValue}
            handleToggleDateRange={handleToggleDateRange}
            handleClearFilters={handleClearFilters}
          />
          {!inboxProviderEventsLoading && (
            <StyledTable
              table={table}
              isHeaderSticky
              testId="to-do"
              placeholderComponent={
                nothingFoundCase ? (
                  <PlaceholderComponent searchError="No results found. Please try another filter options." />
                ) : null
              }
              withPagination
            />
          )}
        </InboxWrapper>
      </PatientNameClickModalWindowsProvider>
      <NotValidTaskModalWindow />
    </>
  );
};
