import {ConnectionNode} from '../constants/providerDetailsItem';
import {ProviderDirectoryLocation} from '../constants/types';

export const getProviderDirectoryAddress = (
  addressDetails: ProviderDirectoryLocation
): {firstLine?: string; secondLine?: string} => {
  if (!addressDetails) {
    return {
      firstLine: '',
      secondLine: '',
    };
  }
  return {
    firstLine: addressDetails.address_1
      ? `${addressDetails.address_1},`
      : undefined,
    secondLine:
      addressDetails.city && addressDetails.state
        ? `${addressDetails.city}, ${addressDetails.state} ${addressDetails.postal_code}`
        : undefined,
  };
};

export const getOrderRecordsAddressConnection = (
  addressDetails: ConnectionNode
): string =>
  `${addressDetails.location_address1},\n${addressDetails.location_city}, ${addressDetails.location_state} ${addressDetails.location_postal_code}`;

export const getOrderRecordsAddress = (
  addressDetails: ProviderDirectoryLocation
): string =>
  `${addressDetails.address_1},\n${addressDetails.city}, ${addressDetails.state} ${addressDetails.postal_code}`;
