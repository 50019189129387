import React, {FC} from 'react';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';

import BlueCheckCircle from '../../../../assets/icons/blueCheckCircle.svg';
import SuccessBlueIcon from '../../../../assets/icons/successBlue.svg';
import {Button} from '../../../shared/components/button';
import {InputHelperText} from '../../../shared/components/form';
import {colors} from '../../../shared/styles/theme';
import {requestOrderRecords} from '../utils/fetchers';

import {OrderRecordItem} from './orderRecords/orderRecordItem';

type Props = {
  title: string;
  description: string;
  seletedTypes: string[];
  selectedDeliveryMethod: string | null;
  onSelectDeliveryMethod: (method: string) => void;
  deliveryNotes: {
    text: string;
    image: string;
    billing_records: string;
  };
  setDeliveryNotes: (note: {
    text: string;
    image: string;
    billing_records: string;
  }) => void;
  onNext: () => void;
  onBack: () => void;
};

export const DeliveryMethodForm: FC<Props> = ({
  title,
  description,
  selectedDeliveryMethod,
  onSelectDeliveryMethod,
  deliveryNotes,
  setDeliveryNotes,
  onNext,
  onBack,
  seletedTypes,
}) => {
  const location = useLocation<{rowData: any; consumerUuid: string}>();
  const {rowData, consumerUuid} = location.state;
  const handlePlaceOrder = async () => {
    try {
      if (seletedTypes.length > 0) {
        seletedTypes?.map(async type => {
          const note =
            deliveryNotes[
              type.toLowerCase() as 'text' | 'image' | 'billing_records'
            ] || '';
          await requestOrderRecords(consumerUuid, rowData.id, [type], note);
        });
      }
    } catch (error) {
      console.error('Error requesting order records', error);
    }
    onNext();
  };

  const renderDeliveryOptions = (
    header: string,
    deliveryMethodOptions: {
      title: string;
      description: string;
      type: string;
      disabled?: boolean;
    }[],
    noteKey: 'text' | 'image' | 'billing_records'
  ) => (
    <>
      <SubHeader>{header}</SubHeader>
      {deliveryMethodOptions.map(({title, description, type, disabled}) => (
        <OrderRecordItem
          key={type}
          iconSrc={BlueCheckCircle}
          iconSelectedSrc={SuccessBlueIcon}
          title={title}
          description={description}
          selected={selectedDeliveryMethod === type}
          onClick={() => onSelectDeliveryMethod(type)}
          disabled={disabled}
        />
      ))}
      {selectedDeliveryMethod === 'standard' && (
        <div>
          <Note
            data-testid={`deliveryNote${noteKey}`}
            placeholder="Add your note (optional)"
            value={deliveryNotes[noteKey]}
            onChange={e =>
              setDeliveryNotes({
                ...deliveryNotes,
                [noteKey]: e.target.value,
              })
            }
            maxLength={300}
          />
          <InputHelperText style={{textAlign: 'end'}}>
            {`${deliveryNotes[noteKey].length}/300`}
          </InputHelperText>
        </div>
      )}
    </>
  );

  return (
    <Container>
      <Title>{title}</Title>
      <Description>{description}</Description>
      {seletedTypes?.includes('TEXT') &&
        renderDeliveryOptions(
          'Order Medical Records',
          [
            {
              title: 'Fast Digital Download',
              description:
                'Receive records immediately by logging in to the provider’s patient portal. We use OneRecord to make this connection.',
              type: 'fast',
              disabled: true,
            },
            {
              title: 'Standard Delivery',
              description:
                'This method can take one to two weeks, but it works for every provider. Find these files in patient’s Medical Files.',
              type: 'standard',
            },
          ],
          'text'
        )}
      {seletedTypes?.includes('IMAGE') &&
        renderDeliveryOptions(
          'Order Images',
          [
            {
              title: 'Standard Delivery',
              description:
                'This method can take one to two weeks, but it works for every provider. Find these files in patient’s Medical Files.',
              type: 'standard',
            },
          ],
          'image'
        )}
      {seletedTypes?.includes('BILLING_RECORDS') &&
        renderDeliveryOptions(
          'Order Billing Records',
          [
            {
              title: 'Standard Delivery',
              description:
                'This method can take one to two weeks, but it works for every provider. Find these files in patient’s Medical Files.',
              type: 'standard',
            },
          ],
          'billing_records'
        )}
      <ButtonContainer>
        <Button variant="outlined" onClick={onBack}>
          Back
        </Button>
        <Button onClick={handlePlaceOrder}>Place Order</Button>
      </ButtonContainer>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 20px;
`;
const ButtonContainer = styled.div`
  margin-top: 40px;
`;
const Note = styled.textarea`
  width: 440px;
  height: 21px;
  max-height: 100px;
  gap: 8px;
  border-radius: 4px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  margin-top: -1px;
  border: 1px solid ${colors.backgroundPrimary};
  border-radius: 4px;
  padding-left: 16px;
  padding-right: 16px;
  resize: none;
  font-family: inherit;
  padding: 10px 12px;
  &:focus {
    border: 2px;
    border-color: ${colors.primary};
    box-shadow: 0 0 0 2px ${colors.primary};
  }
`;
const Title = styled.text`
  font-size: 18px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  line-height: 24px;
  color: ${colors.black};
  margin-bottom: -4px;
`;

const Description = styled.text`
  font-size: 14px;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  line-height: 16px;
  color: ${colors.grey801};
  font-style: italic;
  padding-bottom: 8px;
`;

const SubHeader = styled.text`
  font-size: 14px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  line-height: 24px;
  color: ${colors.black};
  margin-bottom: 8px;
`;
