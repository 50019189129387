export const consumerAPIPrefix = '/healthid-consumerapi/v1';
export const clientAPIPrefix = '/healthid-clientapi/v1';
export const healthIdDataRequestCoordinator =
  '/healthid-data-request-coordinator/v1';
export const healthIdHealthRecords = '/healthid-health-records/v1';
const healthIdCareGuide = '/healthid-care-guide/v1';

export const urls = {
  userInvitation(invitationId) {
    return `${clientAPIPrefix}/invitations/${invitationId}`;
  },
  get invitationEmail() {
    return `${clientAPIPrefix}/invitations/mail`;
  },
  get self() {
    return `${clientAPIPrefix}/users/self`;
  },
  get users() {
    return `${clientAPIPrefix}/users`;
  },
  get recordMfa() {
    return `${clientAPIPrefix}/users/last-mfa`;
  },
  get customToken() {
    return `${clientAPIPrefix}/users/custom-token`;
  },
  get activityLog() {
    return `${consumerAPIPrefix}/consumers/activity-log?size=50`;
  },
  get activityLogExportAsCsv() {
    return `${consumerAPIPrefix}/consumers/activity-log/export-as-csv`;
  },
  get locations() {
    return `${clientAPIPrefix}/locations`;
  },
  get physicalLocations() {
    return `${clientAPIPrefix}/physical-locations`;
  },
  location(locationId) {
    return `${clientAPIPrefix}/locations/${locationId}`;
  },
  physicalLocation(physicalLocationId) {
    return `${clientAPIPrefix}/physical-locations/${physicalLocationId}`;
  },
  get policies() {
    return `${clientAPIPrefix}/policies`;
  },
  get policyTypes() {
    return `${clientAPIPrefix}/policy-types`;
  },
  policy(policyId) {
    return `${clientAPIPrefix}/policies/${policyId}`;
  },
  relyingParty(relyingPartyId) {
    return `${clientAPIPrefix}/relying-parties/${relyingPartyId}`;
  },
  relyingPartyProfile(relyingPartyId) {
    return `${clientAPIPrefix}/relying-parties/${relyingPartyId}/profile`;
  },
  relyingPartyPolicies(relyingPartyId) {
    return `${clientAPIPrefix}/relying-parties/${relyingPartyId}/policies`;
  },
  relyingPartyPoliciesByPolicyType(relyingPartyId, policyTypeId = 'ENTRY') {
    return `${clientAPIPrefix}/relying-parties/${relyingPartyId}/policies?policy_type_id=${policyTypeId}`;
  },
  relyingPartyUsers(relyingPartyId) {
    return `${clientAPIPrefix}/relying-parties/${relyingPartyId}/users`;
  },
  relyingPartyLocations(relyingPartyId) {
    return `${clientAPIPrefix}/relying-parties/${relyingPartyId}/locations`;
  },
  relyingPartyLocationsByPolicyType(relyingPartyId, policyTypeId) {
    return `${clientAPIPrefix}/relying-parties/${relyingPartyId}/locations?policy_type_id=${policyTypeId}`;
  },
  relyingPartyPortalUsersSorted(relyingPartyId, searchPhrase) {
    return `${clientAPIPrefix}/relying-parties/${relyingPartyId}/portal-users?sort_by=createdAt&sort_direction=DESC&search_phrase=${searchPhrase}`;
  },
  relyingPartyPortalUserDetail(relyingPartyId, portalUserId) {
    return `${clientAPIPrefix}/relying-parties/${relyingPartyId}/portal-users/${portalUserId}`;
  },
  relyingPartyInviteUser(relyingPartyId) {
    return `${clientAPIPrefix}/relying-parties/${relyingPartyId}/portal-users`;
  },
  relyingPartyInvitationCode(relyingPartyId) {
    return `${clientAPIPrefix}/relying-parties/${relyingPartyId}/invitation-code`;
  },
  relyingPartyEhrs(relyingPartyId) {
    return `${clientAPIPrefix}/relying-parties/${relyingPartyId}/ehrs`;
  },
  issuerRelyingParties(issuerId) {
    return `${clientAPIPrefix}/issuers/${issuerId}/relying-party`;
  },
  issuerConfirmedRelyingParties(relyingPartyId) {
    return `${clientAPIPrefix}/issuers/${relyingPartyId}/relying-parties?status=CONFIRMED&sort_by=name&sort_direction=ASC`;
  },
  issuerRelyingPartiesSorted(relyingPartyId) {
    return `${clientAPIPrefix}/issuers/${relyingPartyId}/relying-parties?sort_by=createdAt&sort_direction=DESC`;
  },
  issuerPortalUsers(issuerId) {
    return `${clientAPIPrefix}/issuers/${issuerId}/portal-users`;
  },
  issuerPortalUsersSorted(issuerId) {
    return `${clientAPIPrefix}/issuers/${issuerId}/portal-users?sort_by=createdAt&sort_direction=DESC`;
  },
  issuerPortalUserDetail(issuerId, portalUserId) {
    return `${clientAPIPrefix}/issuers/${issuerId}/portal-users/${portalUserId}`;
  },
  checkEmail(email) {
    const encodedEmail = encodeURIComponent(email);
    return `${clientAPIPrefix}/invitations/existence?email=${encodedEmail}`;
  },
  checkPolicyName(value) {
    return `${clientAPIPrefix}/policies/existence?policy_name=${value}`;
  },
  checkLocationName(value) {
    return `${clientAPIPrefix}/locations/existence?location_name=${value}`;
  },
  checkRelyingPartyName(value) {
    return `${clientAPIPrefix}/relying-parties/existence?relying_party_name=${value}`;
  },
  searchDataForNPI(npi, npiType) {
    return `${clientAPIPrefix}/npi/search?npi=${npi}&type=${npiType}`;
  },
  searchAndValidateDataForNPI(npi, npiType) {
    return `${clientAPIPrefix}/npi/search-and-validate?npi=${npi}&type=${npiType}`;
  },
  invitedPatients(relyingPartyId) {
    return `${consumerAPIPrefix}/relying-parties/${relyingPartyId}/patients/invited`;
  },
  enrolledPatients(relyingPartyId) {
    return `${consumerAPIPrefix}/relying-parties/${relyingPartyId}/patients/enrolled`;
  },
  myPatientsConsumerUuids(relyingPartyId) {
    return `${clientAPIPrefix}/users/${relyingPartyId}/boundpatients`;
  },
  createMyPatient(relyingPartyId, consumerUuid) {
    return `${clientAPIPrefix}/users/${relyingPartyId}/boundpatients?consumerUuid=${consumerUuid}`;
  },
  fetchPatientMedicalRecordsRequestsListByIds() {
    return `${healthIdDataRequestCoordinator}/patient-medical-records-requests/list-by-ids`;
  },
  removeMyPatient(relyingPartyId, consumerUuid) {
    return `${clientAPIPrefix}/users/${relyingPartyId}/boundpatients/${consumerUuid}`;
  },
  myPatients(relyingPartyId) {
    return `${consumerAPIPrefix}/relying-parties/${relyingPartyId}/patients`;
  },
  physicians(relyingPartyId) {
    return `${clientAPIPrefix}/users/${relyingPartyId}/physicians`;
  },
  physiciansUUIDS(relyingPartyId) {
    return `${clientAPIPrefix}/users/${relyingPartyId}/boundpatients/search`;
  },
  get invitePatient() {
    return `${consumerAPIPrefix}/invitations`;
  },
  patientInvitation(invitationId) {
    return `${consumerAPIPrefix}/invitations/${invitationId}`;
  },
  resendInvitePatient(invitationId) {
    return `${consumerAPIPrefix}/invitations/${invitationId}/resend`;
  },
  patientDetails(consumerUuid, relyingPartyId) {
    return `${consumerAPIPrefix}/consumers/${consumerUuid}?relying_party_id=${relyingPartyId}`;
  },
  patientLimitedDetails(consumerUuid, relyingPartyId) {
    return `${consumerAPIPrefix}/consumers/${consumerUuid}/limited-data?relying_party_id=${relyingPartyId}`;
  },
  outstandingRecordRequests(relyingPartyId, consumerUuid) {
    return `${clientAPIPrefix}/provider-inbox-events/relying-parties/${relyingPartyId}/consumers/${consumerUuid}/active-medical-records-events`;
  },
  clientShareRequests() {
    return `${healthIdDataRequestCoordinator}/relying-party-connections`;
  },
  deleteConnection(connectionId) {
    return `${healthIdDataRequestCoordinator}/relying-party-connections/${connectionId}`;
  },
  updateMRN(consumerUuid, mrnId) {
    return `${consumerAPIPrefix}/consumers/${consumerUuid}/patient-identifiers/mrns/${mrnId.toString()}`;
  },
  addMRN(consumerUuid) {
    return `${consumerAPIPrefix}/consumers/${consumerUuid}/patient-identifiers/mrns`;
  },
  deleteMRN(consumerUuid, mrnId) {
    return `${consumerAPIPrefix}/consumers/${consumerUuid}/patient-identifiers/mrns/${mrnId.toString()}`;
  },
  shareMedicalRecordsWithProvider() {
    return `${healthIdDataRequestCoordinator}/patient-requests`;
  },
  fileUploadLink() {
    return `${healthIdHealthRecords}/file/upload/link`;
  },
  filePreview() {
    return `${healthIdHealthRecords}/file/preview`;
  },
  fileUploadSave() {
    return `${healthIdHealthRecords}/file/upload/save`;
  },
  fileDownload() {
    return `${healthIdHealthRecords}/file/client/download`;
  },
  medicalDocuments(consumerUuid, sortDirection, fileTypes, page, itemsOnPage) {
    return `${healthIdHealthRecords}/file/client/${consumerUuid}/list?sort-direction=${sortDirection}&file-types=${fileTypes}&page=${page}&size=${itemsOnPage}`;
  },
  patientsNames(relyingPartyId) {
    return `${consumerAPIPrefix}/relying-parties/${relyingPartyId}/patients/names`;
  },
  inboxProviderEvents(pageIndex, pageSize) {
    return `${clientAPIPrefix}/provider-inbox-events/search?page=${pageIndex}&size=${pageSize}`;
  },
  inboxProviderResolutionEvent(eventId) {
    return `${clientAPIPrefix}/provider-inbox-events/${eventId}/resolve`;
  },
  inboxProviderDismissEvent(eventId) {
    return `${clientAPIPrefix}/provider-inbox-events/${eventId}/unresolve`;
  },
  inboxNewEventsCount() {
    return `${clientAPIPrefix}/provider-inbox-events/new`;
  },
  inboxRequestedRecordInfo(eventId) {
    return `${clientAPIPrefix}/provider-inbox-events/${eventId}`;
  },
  patientConnection(relyingPartyId, consumerUuid) {
    return `${healthIdDataRequestCoordinator}/relying-party-connections/recent?relying_party_id=${relyingPartyId}&consumer_uuid=${consumerUuid}`;
  },
  patientDriverLicenceFront(consumerUuid) {
    return `${consumerAPIPrefix}/consumers/${consumerUuid}/driver-license/front`;
  },
  patientDriverLicenceBack(consumerUuid) {
    return `${consumerAPIPrefix}/consumers/${consumerUuid}/driver-license/back`;
  },
  patientInsuranceFront(consumerUuid, patientInsuranceId) {
    return `${consumerAPIPrefix}/consumers/${consumerUuid}/insurance/file?insuranceId=${patientInsuranceId}`;
  },
  patientInsuranceBack(consumerUuid, patientInsuranceId) {
    return `${consumerAPIPrefix}/consumers/${consumerUuid}/insurance/file-back?insuranceId=${patientInsuranceId}`;
  },
  patientInsuranceInfo(consumerUuid, patientInsuranceId, npi) {
    return `${consumerAPIPrefix}/consumers/${consumerUuid}/insurance?insuranceId=${patientInsuranceId}&npi=${npi}`;
  },
  insuranceEligibilityCheck(relyingPartyId, insuranceId) {
    return `${clientAPIPrefix}/relying-parties/${relyingPartyId}/insurance/${insuranceId}/eligibility`;
  },
  get uploadIssuerLogo() {
    return `${clientAPIPrefix}/issuers/logo`;
  },
  get getIssuerLogo() {
    return `${clientAPIPrefix}/issuers/logo`;
  },
  getLocationsAddresses(
    providerType,
    npi,
    zipCode,
    removeInnerPhysicalLocations
  ) {
    return `${clientAPIPrefix}/providers/${providerType}/${npi}/locations?remove_inner_physical_locations=${removeInnerPhysicalLocations}&zip_code=${zipCode}`;
  },
  getProviderIndividualsSearchResults(
    content = {name: '', city: '', state: ''},
    limit = 50,
    skip = 0
  ) {
    return `${clientAPIPrefix}/providers/individuals?name=${encodeURIComponent(content.name)}&city=${content.city ?? ''}&state=${content.state ?? ''}&limit=${limit}&skip=${skip}`;
  },
  getProviderOrganizationSearchResults(
    consumerUuid,
    content = {name: '', city: '', state: ''},
    limit = 50,
    skip = 0
  ) {
    return `${clientAPIPrefix}/consumers/${consumerUuid}/providers/organizations?name=${encodeURIComponent(content.name)}&city=${content.city ?? ''}&state=${content.state ?? ''}&limit=${limit}&skip=${skip}`;
  },
  getProviderIndividual(consumerUuid, npi) {
    return `${clientAPIPrefix}/consumers/${consumerUuid}/providers/individuals/${npi}`;
  },
  getProvider(
    isOrganization = false,
    content = {name: '', city: '', state: ''},
    limit = 50,
    skip = 0
  ) {
    return `${clientAPIPrefix}/providers/${isOrganization ? 'organizations' : 'individuals'}?name=${encodeURIComponent(content.name)}&city=${content.city ?? ''}&state=${content.state ?? ''}&limit=${limit}&skip=${skip}`;
  },
  providerNotFound(consumerUuid, isOrganization) {
    return `${clientAPIPrefix}/consumers/${consumerUuid}/providers/${isOrganization ? 'organizations' : 'individuals'}/not-found`;
  },
  getProvidersOfConsolidatedMedicalRecord(consumerUuid) {
    return `${healthIdHealthRecords}/fhir/consumers/${consumerUuid}/providers`;
  },
  getCareTeamsOfConsolidatedMedicalRecord(consumerUuid) {
    return `${healthIdHealthRecords}/fhir/consumers/${consumerUuid}/care-teams`;
  },
  getMedicationsOfConsolidatedMedicalRecord(consumerUuid) {
    return `${healthIdHealthRecords}/fhir/consumers/${consumerUuid}/medications`;
  },
  getProceduresOfConsolidatedMedicalRecord(consumerUuid) {
    return `${healthIdHealthRecords}/fhir/consumers/${consumerUuid}/procedures`;
  },
  getConditionsOfConsolidatedMedicalRecord(consumerUuid) {
    return `${healthIdHealthRecords}/fhir/consumers/${consumerUuid}/conditions`;
  },
  getAllergiesOfConsolidatedMedicalRecord(consumerUuid) {
    return `${healthIdHealthRecords}/fhir/consumers/${consumerUuid}/allergies`;
  },
  getImmunizationsOfConsolidatedMedicalRecord(consumerUuid) {
    return `${healthIdHealthRecords}/fhir/consumers/${consumerUuid}/immunizations`;
  },
  getImageStudiesOfConsolidatedMedicalRecord(consumerUuid) {
    return `${healthIdHealthRecords}/fhir/consumers/${consumerUuid}/image-studies`;
  },
  getFamilyHistoriesOfConsolidatedMedicalRecord(consumerUuid) {
    return `${healthIdHealthRecords}/fhir/consumers/${consumerUuid}/family-histories`;
  },
  getAIChat(consumerUuid) {
    return `${healthIdCareGuide}/ai-chat/${consumerUuid}`;
  },
  getAIChatFileLink(consumerUuid) {
    return `${healthIdCareGuide}/file/consumers/${consumerUuid}/upload-link`;
  },
  getAIChatHistory(consumerUuid) {
    return `${healthIdCareGuide}/ai-chat/history/${consumerUuid}`;
  },
  getAIConversation(conversationId) {
    return `${healthIdCareGuide}/ai-chat/conversation/${conversationId}`;
  },
  getMedicalRecordsOrders(consumerUuid) {
    return `${healthIdDataRequestCoordinator}/consumers/${consumerUuid}/patient-medical-records-requests`;
  },
  getPatientConnections(consumerUuid) {
    return `${healthIdDataRequestCoordinator}/consumers/${consumerUuid}/patient-connections?statuses=CONNECTED`;
  },
  requestOrderRecords(consumerUuid, connectionId) {
    return `${healthIdDataRequestCoordinator}/consumers/${consumerUuid}/patient-connections/${connectionId}/requests`;
  },
  getDeliveryTime(relyingPartyId) {
    return `${healthIdDataRequestCoordinator}/patient-medical-records-requests/relying-parties/${relyingPartyId}/delivery-time`;
  },
  getPatientConnectionsPreferences(consumerUuid, connectionId) {
    return `${healthIdDataRequestCoordinator}/consumers/${consumerUuid}/patient-connections/${connectionId}`;
  },
  requestOrderRecords(consumerUuid, connectionId) {
    return `${healthIdDataRequestCoordinator}/consumers/${consumerUuid}/patient-connections/${connectionId}/requests`;
  },
};
