import {EVENTS_CATEGORY, EVENTS_CATEGORY_DROPDOWN} from './types';

export const CATEGORY_FILTERS_LABELS = {
  [EVENTS_CATEGORY.PATIENT_DENIED_CONNECTION_REQUEST]: 'Connection Denied',
  [EVENTS_CATEGORY.PATIENT_CREATE_CONNECTION_REQUEST_EXPIRED]:
    'Connection Request Expired',
  [EVENTS_CATEGORY.PATIENT_INVITATION_EXPIRED]: 'Invitation Expired',
  [EVENTS_CATEGORY.PATIENT_DISCONNECTED]: 'Patient Disconnected',
  [EVENTS_CATEGORY.PATIENT_DENIED_SHARE_REQUEST]: 'Sharing Denied',
  [EVENTS_CATEGORY.PATIENT_CHANGE_CONNECTION_REQUEST_EXPIRED]:
    'Sharing Request Expired',
  [EVENTS_CATEGORY.PATIENT_REQUESTED_DOCUMENT_UPLOAD]: 'Upload Medical Record',
  [EVENTS_CATEGORY.PATIENT_REQUESTED_DOCUMENT_UPDATE]: 'Update Medical Record',
  [EVENTS_CATEGORY.PATIENT_REQUESTED_TEXT_DOCUMENT_UPLOAD]:
    'Upload Medical Records',
  [EVENTS_CATEGORY.PATIENT_REQUESTED_TEXT_DOCUMENT_UPDATE]:
    'Upload Medical Records',
  [EVENTS_CATEGORY.PATIENT_REQUESTED_IMAGE_DOCUMENT_UPLOAD]: 'Upload Images',
  [EVENTS_CATEGORY.PATIENT_REQUESTED_IMAGE_DOCUMENT_UPDATE]: 'Update Images',
  [EVENTS_CATEGORY.PATIENT_REQUESTED_BILLING_DOCUMENT_UPLOAD]:
    'Upload Billing Record',
  [EVENTS_CATEGORY.PATIENT_REQUESTED_BILLING_DOCUMENT_UPDATE]: 'Update Billing',
  [EVENTS_CATEGORY.PATIENT_RECORD_UPDATE_RECEIVED]: 'Order Completed',
};

export const CATEGORY_EVENTS_DROPDOWN_LABELS = {
  [EVENTS_CATEGORY_DROPDOWN.CONNECTION_DENIED]: 'Connection Denied',
  [EVENTS_CATEGORY_DROPDOWN.CONNECTION_REQUEST_EXPIRED]:
    'Connection Request Expired',
  [EVENTS_CATEGORY_DROPDOWN.INVITATION_EXPIRED]: 'Invitation Expired',
  [EVENTS_CATEGORY_DROPDOWN.DISCONNECTED]: 'Patient Disconnected',
  [EVENTS_CATEGORY_DROPDOWN.SHARING_DENIED]: 'Sharing Denied',
  [EVENTS_CATEGORY_DROPDOWN.SHARING_REQUEST_EXPIRED]: 'Sharing Request Expired',
  [EVENTS_CATEGORY_DROPDOWN.UPLOAD_IMAGES]: 'Upload Images',
  [EVENTS_CATEGORY_DROPDOWN.UPLOAD_TEXT_RECORDS]: 'Upload Medical Records',
  [EVENTS_CATEGORY_DROPDOWN.UPLOAD_BILLING_RECORDS]: 'Upload Billing Records',
  [EVENTS_CATEGORY_DROPDOWN.RECORD_UPDATE_RECEIVED]: 'Order Completed',
};
