import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';

import {Button} from '../../../../shared/components/button';
import {useErrorHandling} from '../../../../shared/hooks/useErrorHandling';
import {capitalizeParser} from '../../../../shared/utils/captalizeParser';
import {
  ConnectionNode,
  ProviderDetailsItem,
} from '../../constants/providerDetailsItem';
import {fetchIndividualLocations} from '../../utils/fetchers';

import {SearchResults} from './searchResults';

interface SelectLocationProps {
  provider: ProviderDetailsItem;
  onNext: () => void;
  onBack: () => void;
  isOrganization?: boolean;
  setLocation: React.Dispatch<React.SetStateAction<ConnectionNode>>;
  setProvider: React.Dispatch<React.SetStateAction<ProviderDetailsItem>>;
  location: ConnectionNode;
}

export const SelectLocation: React.FC<SelectLocationProps> = ({
  onNext,
  onBack,
  setProvider,
  provider,
  setLocation,
  location,
  isOrganization = false,
}) => {
  const {patientUuid} = useParams<{patientUuid: string}>();
  const [locations, setLocations] = useState<ConnectionNode[]>([]);
  const handleError = useErrorHandling();

  useEffect(() => {
    async function fetchData() {
      if (isOrganization) {
        setLocations(provider.connection_nodes ?? []);
      } else {
        try {
          const response: ProviderDetailsItem = await fetchIndividualLocations(
            patientUuid,
            provider.provider_npi
          );
          setLocations(response?.connection_nodes ?? []);
          setProvider(response);
        } catch (error) {
          if (error instanceof Error) {
            return handleError(error);
          }
        }
      }
    }
    fetchData();
  }, [
    patientUuid,
    isOrganization,
    provider.provider_npi,
    handleError,
    setProvider,
    provider,
  ]);

  return (
    <Container>
      <Title>Select Location</Title>
      {provider &&
        `${capitalizeParser(provider?.provider_name)} provides services at the following locations.`}
      {locations.length > 0 && (
        <>
          <SearchResults
            isOrganization={isOrganization}
            locations={locations}
            isLocations
            setLocation={setLocation}
          />
        </>
      )}
      <ButtonContainer>
        <Button variant="outlined" onClick={onBack}>
          Back
        </Button>
        <Button
          disabled={Object.keys(location).length === 0}
          onClick={onNext}
          type="submit"
        >
          Next
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ButtonContainer = styled.div`
  margin-top: 40px;
  display: flex;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
`;
