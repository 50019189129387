import React, {FC, ReactNode, useMemo} from 'react';
import ReactDOM from 'react-dom';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';

import {drawerState} from '../../main/portalPatients/atoms/drawerState';
import {colors} from '../styles/theme';

const useStyles = (width: number) =>
  makeStyles(theme => ({
    drawerRoot: {
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    drawerClose: {
      width: 0,
      transition: theme.transitions.create(['width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    drawerOpen: {
      width,
      transition: theme.transitions.create(['width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  }));

interface IStyledDrawerProps {
  children: ReactNode;
  drawerWidth: number;
  elementId?: string | null;
}

export const closeDrawer = (
  handleClose: () => void,
  ref: React.RefObject<HTMLDivElement>
): (() => void) => {
  const handleClickOutside = (event: MouseEvent) => {
    if (
      ref.current &&
      !ref.current.contains(event.target as HTMLDivElement) &&
      document.getElementById('root')?.contains(event.target as HTMLDivElement)
    ) {
      handleClose();
    }
  };

  document.addEventListener('click', handleClickOutside, true);
  return () => {
    document.removeEventListener('click', handleClickOutside, true);
  };
};

export const Drawer: FC<IStyledDrawerProps> = ({
  children,
  drawerWidth,
  elementId,
}) => {
  const classes = useStyles(drawerWidth)();

  const drawer = useRecoilValue(drawerState);
  const drawerComponent = useMemo(
    () => (
      <DrawerContainer
        data-testid="ai-chat-drawer"
        className={clsx(classes.drawerRoot, {
          [classes.drawerOpen]: drawer.isOpen,
          [classes.drawerClose]: !drawer.isOpen,
        })}
      >
        {children}
      </DrawerContainer>
    ),
    [
      children,
      classes.drawerClose,
      classes.drawerOpen,
      classes.drawerRoot,
      drawer,
    ]
  );

  const container = document.getElementById(elementId ?? 'page-body');

  return container ? ReactDOM.createPortal(drawerComponent, container) : null;
};

const DrawerContainer = styled.div`
  border-left: 1px solid ${colors.grey50};
  box-sizing: border-box;
  box-shadow: -3px 0px 8px 0px rgba(176, 176, 176, 0.25);
`;
